import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Edit, Loader} from "../components/icons";
import Table from "../components/table";
import Pagination from "../components/pagination";
import ErrorModal from "../components/error-modal";
import Button from "../components/button";
import Row from "../components/row";
import * as Yup from "yup";
import EditModal from "../components/edit-modal";
import TextInput from "../components/text-input";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";

export default function Grosshaendler() {

  const pageSize = 10;

  const crud = useCRUD();

  const [wholesalers, setWholesalers] = useState([]);
  const [totalWholesalers, setTotalWholesalers] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Name', orderBy: 'name', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showWholesalerEdit, setShowWholesalerEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read(
      {entity: 'wholesaler', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order, filter: filter.length ? crud.filter.and(...filter) : {}})
      .then( (result) => {
        setTotalWholesalers(result.total);
        setWholesalers(result.items);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{name: 'name', title: 'Name'}} sortable filterable/>,
    <TableHead titleRight resetFilter/>
  ];
  const modalValidationSchema = Yup.object({
    name: Yup.string()
      .required('Bitte eine Bezeichnung angeben')
  });

  return (
    <Screen className="GrosshaendlerPage">
      <h1 className="text-center">Großhändler</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Großhändler anlegen" onClick={() => setShowWholesalerEdit(
            {
              id: '',
              name: ''
            }
          )} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="wholesalers-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalWholesalers === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && wholesalers.length > 0 &&
          <>
            {wholesalers.map((wholesaler, index) =>
              <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                <td>
                  <AutoTitle>{wholesaler.name}</AutoTitle>
                </td>
                <td className='text-right icon-row'>
                  <div onClick={() => setShowWholesalerEdit(wholesaler)} title="Großhändler bearbeiten">
                    <Edit/>
                  </div>
                </td>
              </tr>
            )}
          </>
        }
      </Table>
      {
        !loading && totalWholesalers > wholesalers.length &&
        <Pagination className="mt-2" total={totalWholesalers} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showWholesalerEdit &&
        <EditModal title="Großhändler" itemId={showWholesalerEdit.id ?? null} validationSchema={modalValidationSchema}
          databaseName="wholesaler" columns="1"
          onDismiss={() => {
            setShowWholesalerEdit(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}
        >
          <div>
            <TextInput value={showWholesalerEdit.name} name="name" label="Name" required/>
          </div>
        </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
