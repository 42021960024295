import {useField, useFormikContext} from "formik";
import './date-time-input.scss';
import {toDateString, toIsoDateString} from "../functions";
import Error from "./error";

export default function DateTimeInput({name, label, placeholder, required, className}) {

  const [field, meta, helpers] = useField(name);
  const {setValue, setTouched} = helpers;
  const {isSubmitting} = useFormikContext();

  return (
    <div
      className={
        'DateTimeInput' + (meta.touched && meta.error ? ' has-error' : '') + (required ? ' required' : '')
        + (className ? ' ' + className : '')
      }
    >
      {label && <label>{label}</label>}
      <div className="wrapper">
        <input
          type="date" placeholder={placeholder} disabled={isSubmitting}
          value={toDateString(new Date(field.value))}
          onChange={(picker) => {
            setTouched(true);
            setValue(toIsoDateString(new Date(picker.target.value)));
          }}
        />
      </div>
      <Error className="mt-025">{meta.touched && meta.error}</Error>
    </div>
  );

}
