import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Edit, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import Row from "../components/row";
import * as Yup from "yup";
import DropzoneInput from "../components/DropzoneInput";
import TextInput from "../components/text-input";
import EditModal from "../components/edit-modal";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";

export default function Marken() {

  const pageSize = 10;

  const crud = useCRUD();

  const [brands, setBrands] = useState([]);
  const [totalBrands, setTotalBrands] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Name', orderBy: 'name', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showBrandEdit, setShowBrandEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read(
      {entity: 'brand', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order, filter: filter.length ? crud.filter.and(...filter) : {}})
      .then(async (brandBulkReadResult) => {
        setBrands(await crud.expand(brandBulkReadResult.items, ['image:file']));
        setTotalBrands(brandBulkReadResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{title: 'Bild'}}/>,
    <TableHead property={{name: 'name', title: 'Name'}} sortable filterable/>,
    <TableHead titleRight resetFilter/>
  ];
  const modalValidationSchema = Yup.object({
    name: Yup.string()
      .required('Bitte eine Bezeichnung angeben'),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      )
  });
  console.log(brands);
  return (
    <Screen className="BrandPage">
      <h1 className="text-center">Marken</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neue Marke anlegen" onClick={() => setShowBrandEdit(
            {
              image: {
                url: '',
                filename: ''
              },
              name: ''
            }
          )} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="brands-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalBrands === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && brands.length > 0 &&
            <>
              {brands.map((brand, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td>
                    <div className="TeaserImage">
                      {
                        brand.image &&
                        <img src={brand.image && brand.image.url} alt={brand.image.filename}/>
                      }
                    </div>
                  </td>
                  <td>
                    <AutoTitle>{brand.name && brand.name}</AutoTitle>
                  </td>
                  <td className="text-right icon-row">
                    <div onClick={() => setShowBrandEdit(brand)} title="Marke bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>)}
            </>
        }
      </Table>
      {
        !loading && totalBrands > brands.length &&
        <Pagination className="mt-2" total={totalBrands} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showBrandEdit &&
      <EditModal title="Marke" itemId={showBrandEdit.id ?? null} validationSchema={modalValidationSchema}
        databaseName="brand"
        onDismiss={() => {
          setShowBrandEdit(null);
          setReloadKey(reloadKey => reloadKey + 1);
        }}
      >
        <div className="image">
          {
            showBrandEdit.image &&
            <img src={showBrandEdit.image && showBrandEdit.image.url} alt={showBrandEdit.image.filename}/>
          }
          <DropzoneInput name="image" label=""
            value={
              showBrandEdit?.image?.filename ?
                {
                  ...showBrandEdit.image,
                  name: showBrandEdit.image.filename,
                  type: showBrandEdit.image.media_type
                } :
                null
            }/>
        </div>
        <div>
          <TextInput name="name" label="Name" value={showBrandEdit.name} required/>
        </div>
      </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}

