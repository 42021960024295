export {displayPrice, toDateString, toIsoDateString, displayWholesaler};

function displayPrice(price) {
  return Intl.NumberFormat(
    'de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2}
  ).format(price / 100);
}

function toDateString(isoDateString) {
  const numToString = function(num) {
    return (num < 10 ? '0' : '') + num;
  };
  return (
    `${('000' + isoDateString.getFullYear()).slice(-4)}` +
    `-${numToString(isoDateString.getMonth() + 1)}` +
    `-${numToString(isoDateString.getDate())}`
  );
}

function toIsoDateString(date) {
  const numToString = function(num) {
    return (num < 10 ? '0' : '') + num;
  };
  return (
    `${('000' + date.getFullYear()).slice(-4)}` +
    `-${numToString(date.getMonth() + 1)}` +
    `-${numToString(date.getDate())}` +
    `T00:00:00+00:00`
  );
}

function displayWholesaler(order) {
  let result = '';
  if (order.custom_wholesaler) {
    result = order.custom_wholesaler;
  } else {
    if (order.wholesaler_id) {
      result = order.wholesaler ? order.wholesaler.name : 'Gelöscht';
    } else {
      result = 'Direktabrechnung';
    }
  }
  return result;
}
