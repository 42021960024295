import useCRUD from "../hook/useCRUD";
import ChangePassword from "../components/change-password";
import {useEffect, useState} from "react";
import useClaims from "../hook/useClaims";
import Button from "../components/button";
import Row from "../components/row";
import IconAndText from "../components/icon-and-text";
import {Loader} from "../components/icons";
import ChangeProfile from "../components/change-profile";
import DottedCard from "../components/dotted-card";
import ErrorModal from "../components/error-modal";
import Screen from "../screen-element/screen";

export default function Profil() {

  const crud = useCRUD();
  const claims = useClaims();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changeProfile, setChangeProfile] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'user', id: claims.user_id})
      .then((user) => {
        setUser(user);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [claims.user_id, crud]);

  return (
    <Screen>
      {changePassword && <ChangePassword onDismiss={() => setChangePassword(false)}/>}
      {changeProfile && <ChangeProfile onDismiss={() => setChangeProfile(false)}/>}
      <h1>Mein Profil</h1>
      {loading && <IconAndText icon={<Loader/>} text="Laden…" align="left"/>}
      {
        !loading && user &&
          <Row className="mt-1" columns={{default: 1, md: 2, xl: 3}}>
            <DottedCard>
              <div>
                <div>
                  <span>Name</span>
                  <p>{user.first_name} {user.last_name}</p>
                </div>
                <div>
                  <span>E-Mail</span>
                  <p>{user.email}</p>
                </div>
                <div>
                  <span>Passwort</span>
                  <p>************</p>
                </div>
                <div>
                  <Button text="Passwort ändern" color="primary" onClick={() => setChangePassword(true)}/>
                  <Button text="Profil bearbeiten" inverted onClick={() => setChangeProfile(true)}/>
                </div>
              </div>
            </DottedCard>
          </Row>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
