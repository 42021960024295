import "./amount-selection.scss";
import {Minus, Plus, Trash} from "./icons";
import {matches} from "@testing-library/jest-dom/dist/utils";

export default function AmountSelection({amount = 1, min = 1, max = 99, onChange, freeGood = false, disabled = false}) {

  return (
    <div className="AmountSelection">
      <button
        onClick={() => onChange(amount - 1)} disabled={(disabled) || (amount <= min)}
        className={freeGood ? "hidden" : ""}
      >
        {(min !== 0 || amount !== 1) && <Minus/>}
        {min === 0 && amount === 1 && <Trash/>}
      </button>
      <input
        value={amount}
        onChange={e => matches(e.target.value, /^\d\d?$/) && onChange(Math.max(min, Math.min(e.target.value, max)))}
        onKeyDown={e => {
          e.key === "ArrowUp" && onChange(Math.max(min, Math.min(parseInt(e.target.value) + 1, max)));
          e.key === "ArrowDown" && onChange(Math.max(min, Math.min(parseInt(e.target.value) - 1, max)));
        }}
        disabled={disabled || freeGood}
      />
      <button
        onClick={() => onChange(amount + 1)} disabled={(disabled) || (amount >= max)}
        className={freeGood ? "hidden" : ""}
      >
        <Plus/>
      </button>
    </div>
  );

}
