import "./pagination.scss";
import {ArrowCircle} from "./icons";

export default function Pagination({total, page, pageSize, setPage, className}) {

  const totalPages = Math.ceil(total / pageSize);

  let showPages = [];

  let leftBoundShift = 0;
  let rightBoundShift = 0;
  if (page < 3) {
    rightBoundShift = 2 - page;
  } else if (page > (totalPages - 3)) {
    leftBoundShift = (3 - (totalPages - page));
  }

  for (let i = Math.max(page - 2 - leftBoundShift, 0); i <= Math.min(page + 2 + rightBoundShift, totalPages - 1); i++) {
    showPages.length < 5 && showPages.push(i);
  }

  return (
    <div className={'Pagination' + (className ? ` ${className}` : '')}>
      <div
        className={['arrow-left'].concat(page === 0 ? 'inactive' : []).join(' ')}
        onClick={() => setPage(Math.max(page - 1, 0))}
      >
        <ArrowCircle orientation="left"/>
      </div>
      <div
        className={['arrow-right'].concat(page >= totalPages - 1 ? 'inactive' : []).join(' ')}
        onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
      >
        <ArrowCircle/>
      </div>
      <div className="pages">
        {
          showPages.map(showPage => (
            <button key={showPage} className={showPage === page ? 'active' : null} onClick={() => setPage(showPage)}>
              <span>{showPage + 1 < 10 ? + (showPage + 1) : showPage + 1}</span>
            </button>
          ))
        }
      </div>
    </div>
  );

}
