import "./bestellungen.scss";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import Pagination from "../components/pagination";
import {Arrow, Filter, FilterOff, Loader, X} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Screen from "../screen-element/screen";
import ErrorModal from "../components/error-modal";
import {useNavigate, useParams} from "react-router-dom";
import Row from "../components/row";
import Button from "../components/button";
import {displayPrice, displayWholesaler} from "../functions";
import AutoTitle from "../components/auto-title";
import useBL from "../hook/useBL";
import {Form, Formik} from "formik";
import TextInput from "../components/text-input";

export default function Bestellungen() {

  const pageSize = 10;

  const crud = useCRUD();
  const bl = useBL();
  const params = useParams();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [totalOrders, setTotalOrders] = useState();
  const [filter, setFilter] = useState({});
  const [openFilter, setOpenfilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    Promise.all([
      bl.list_orders({page, page_size: pageSize, filter, order, order_by: orderBy}),
      crud.data.read({entity: 'user', id: params.id ?? 0})
    ])
      .then(async ([orderResult, userResult]) => {
        setOrders(orderResult.items);
        setTotalOrders(orderResult.total);
        setUser(userResult);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
    return () => clearTimeout(timeout);
  }, [bl, crud.data, crud.data.bulk, crud.filter, page, params.id, openFilter, filter, order, orderBy]);

  const generateTableHead = (name, text, setFieldValue, values) =>
    <th onClick={() => {
      if (openFilter !== name) {
        setOpenfilter(name);
      }
    }}>
      <div className="title">
        {text}
        {values[name] && <Filter/>}
        {name === "price" && (values.price_from || values.price_to) && <Filter/>}
      </div>
      {
        openFilter === name &&
        <>
          <div className="closer" onClick={() => setOpenfilter(null)}/>
          <div className="popup">
            <div
              onClick={() => {
                setOrder("asc");
                setOrderBy(name);
              }}
              className={"clickable order" + ((orderBy === name && order === "asc") ? " selected" : "")}
            >
              <Arrow orientation="up"/>
              Aufsteigend&nbsp;sortieren
            </div>
            <div
              onClick={() => {
                setOrder("desc");
                setOrderBy("id");
              }}
              className={
                "clickable order" + ((orderBy === name && order === "desc") ? " selected" : "")
              }
            >
              <Arrow orientation="down"/>
              Absteigend&nbsp;sortieren
            </div>
            {
              name !== "date" && name !== "price" &&
              <TextInput
                name={name} label={text} className="text-left" icon={<X/>}
                onIconClick={() => setFieldValue(name, "")}
              />
            }
            {
              name === "price" &&
              <>
                <TextInput
                  label="von €"
                  className="text-right text-field"
                  name="price_from"
                  icon={<X/>}
                  onIconClick={() => setFieldValue('price_from', '')}
                />
                <TextInput
                  label="bis €"
                  className="text-right text-field"
                  name="price_to"
                  icon={<X/>}
                  onIconClick={() => setFieldValue('price_to', '')}
                />
              </>
            }
            <Button text="Fertig" onClick={() => setOpenfilter(null)} inverted/>
          </div>
        </>
      }
    </th>;

  return (
    <Screen className="User-Orders">
      {
        !showLoading && params.id &&
        <h1 className="text-center">{`Bestellungen von ${user?.username || user?.email}`}</h1>
      }
      {
        !params.id &&
        <h1 className="text-center">Bestellungen</h1>
      }
      {
        params.id &&
        <Row columns={{default: 1, lg: 2}} gap='3'>
          <div>
            <Button icon={<Arrow orientation="left"/>} onClick={() => navigate(-1, {replace: true})} text="zurück"
              inline color="background" inverted/>
          </div>
        </Row>
      }
      <Formik
        initialValues={{
          id: "",
          email: "",
          pos: "",
          wholesaler: "",
          price_from: "",
          price_to: "",
        }}
        onSubmit={() => {
        }}
        validate={values => setFilter({
          ...values,
          price_from: (parseFloat(values.price_from.replace(',', '.')) * 100) || 0,
          price_to: (parseFloat(values.price_to.replace(',', '.')) * 100) || 999_999_999
        })}
      >
        {({setFieldValue, values, resetForm}) => (
          <Form>
            {
              <table
                className={!params.id ? "order-table" : "customer-order-table"}>
                <thead>
                  <tr>
                    {generateTableHead("id", "Bestellnummer", setFieldValue, values)}
                    {!params.id && generateTableHead("email", "Kunden E-Mail", setFieldValue, values)}
                    {generateTableHead("date", "Datum", setFieldValue, values)}
                    {generateTableHead("pos", "Geschäft", setFieldValue, values)}
                    {generateTableHead("wholesaler", "Großhändler", setFieldValue, values)}
                    {generateTableHead("price", "Gesamtsumme", setFieldValue, values)}
                    {
                      !Object.values(values).every(x => x === null || x === "")
                      && <th onClick={() => {
                        setFilter({});
                        resetForm();
                      }}>
                        <FilterOff/>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    loading && showLoading &&
                  <tr>
                    <td className="col-span">
                      <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
                    </td>
                  </tr>
                  }
                  {
                    !showLoading && totalOrders === 0 &&
                  <tr>
                    <td className="col-span">
                      <h2>Keine Einträge vorhanden.</h2>
                    </td>
                  </tr>
                  }
                  {
                    !showLoading && orders.length > 0 && orders.length > 0 &&
            <>
              {orders.map((order, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td><AutoTitle>{order.id}</AutoTitle></td>
                  {
                    !params.id &&
                    <td><AutoTitle>{order.email}</AutoTitle></td>
                  }
                  <td><AutoTitle>{new Date(order.date)
                    .toLocaleString('de-DE', {
                      timeZone: 'Europe/Berlin',
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    }) + ' Uhr'}</AutoTitle></td>
                  <td><AutoTitle>{order.address}</AutoTitle></td>
                  <td><AutoTitle>{displayWholesaler(order)}</AutoTitle></td>
                  <td className="text-right"><AutoTitle>{displayPrice(order.total_price)}</AutoTitle></td>
                  <td/>
                </tr>)}
            </>
                  }
                </tbody>
              </table>
            }
          </Form>
        )}
      </Formik>
      {
        !loading && totalOrders > orders.length &&
        <Pagination className="mt-2" total={totalOrders} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      <ErrorModal errors={errors}/>
    </Screen>
  );

}
