import "./edit-shop-modal.scss";
import Modal from "./modal";
import Row from "./row";
import Button from "./button";
import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import TextInput from "./text-input";
import * as Yup from "yup";
import {useParams} from "react-router-dom";

export default function EditShopModal({user, shop, onDismiss}) {

  const crud = useCRUD();
  const params = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  let customerId = params.id || (shop.owner_id ? shop.owner_id : "");
  const userProps = user ?? {};
  const [copyInvoiceAddress, setCopyInvoiceAddress] = useState(true);

  let posvalidate = {
    customer_number : Yup.string().required('Bitte geben Sie eine Kundennummer ein'),
    invoice_name : Yup.string().required('Bitte geben Sie eine Bezeichnung ein'),
    invoice_street : Yup.string().required('Bitte geben Sie einen Straßennamen ein'),
    invoice_postal_code : Yup.string().required('Bitte geben Sie eine PLZ ein'),
    invoice_city : Yup.string().required('Bitte geben Sie eine Stadt ein')
  };

  if (!copyInvoiceAddress) {
    posvalidate.shipping_name = Yup.string().required('Bitte geben Sie eine Bezeichnung ein');
    posvalidate.shipping_street = Yup.string().required('Bitte geben Sie eine Straße ein');
    posvalidate.shipping_postal_code = Yup.string().required('Bitte geben Sie eine PLZ ein');
    posvalidate.shipping_city = Yup.string().required('Bitte geben Sie eine Stadt ein');
  }

  if (!customerId) {
    posvalidate.email = Yup.string()
      .required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte eine gültige E-Mail-Adresse eingeben')
      .test('validEmail', 'Kein Kunde mit dieser E-Mail-Adresse gefunden', async (value) => {
        return await selectCustomer(value) > 0;
      });
  }

  posvalidate = Yup.object(posvalidate);

  async function selectCustomer (email) {
    let customer_id = params.id ? params.id : 0;
    if (customer_id && customer_id > 0) {
      return customer_id;
    } else if (email !== undefined && email !== '') {
      await crud.data.read({
        entity: "user", filter: {property: "email", operator: "equals", value: email}
      }).then((result) => {
        if (result !== undefined) {
          customer_id = result.id;
        }
      })
        .catch(customer_id = '');
    } else {
      return customer_id;
    }
    return customer_id;
  }

  function updateShopValues(values) {
    shop.invoice_address.name = values.invoice_name;
    shop.invoice_address.city = values.invoice_city;
    shop.invoice_address.street = values.invoice_street;
    shop.invoice_address.postal_code = values.invoice_postal_code;
    if (copyInvoiceAddress) {
      shop.shipping_address.name = values.invoice_name;
      shop.shipping_address.city = values.invoice_city;
      shop.shipping_address.street = values.invoice_street;
      shop.shipping_address.postal_code = values.invoice_postal_code;
    } else {
      shop.shipping_address.name = values.shipping_name;
      shop.shipping_address.city = values.shipping_city;
      shop.shipping_address.street = values.shipping_street;
      shop.shipping_address.postal_code = values.shipping_postal_code;
    }
  }

  const submit = async (values, setSubmitting) => {

    let withoutErrors = true;
    let ownerId = shop.owner_id ? shop.owner_id : await selectCustomer(values.email);
    if (!values.customer_id && !shop.owner_id) {
      if (!customerId) {
        ownerId = await selectCustomer(values.email);
      } else {
        ownerId = customerId;
      }
    }

    for (const key in values) {
      if (values[key] && values[key].length > 0) {
        values[key] = values[key].trim();
      }
    }
    if (shop && shop.invoice_address_id && shop.shipping_address_id) {updateShopValues(values);}
    values.owner_id = customerId ? customerId : await selectCustomer(values.email);
    async function uploadAddresses(shop, values) {
      if (values.owner_id) {
        return new Promise(async resolve => {
          let addressIDs = {};

          const invoiceAddr = {
            name: values.invoice_name,
            street: values.invoice_street,
            owner_id: ownerId,
            city: values.invoice_city,
            postal_code: values.invoice_postal_code
          };
          const shippingAddr = {
            name: values.shipping_name,
            street: values.shipping_street,
            owner_id: ownerId,
            city: values.shipping_city,
            postal_code: values.shipping_postal_code
          };

          if (copyInvoiceAddress) {
            shippingAddr.name = values.invoice_name;
            shippingAddr.street = values.invoice_street;
            shippingAddr.city = values.invoice_city;
            shippingAddr.postal_code = values.invoice_postal_code;
          }

          if (!shop.invoice_address) {
            addressIDs.invoice_address_id = null;
            await crud.data.create({entity: 'address', item: invoiceAddr})
              .then(invoiceResult => addressIDs.invoice_address_id = invoiceResult.id);
          } else if (shop.invoice_address_id) {
            await crud.data.update({entity: 'address', id: shop.invoice_address_id, update: shop.invoice_address});
            addressIDs.invoice_address_id = shop.invoice_address_id;
          } else if (invoiceAddr.name || invoiceAddr.street || invoiceAddr.postal_code || invoiceAddr.city) {
            shop.invoice_address = {...shop.invoice_address, owner_id: customerId};
            await crud.data.create({entity: 'address', item: shop.invoice_address})
              .then(invoiceResult => addressIDs.invoice_address_id = invoiceResult.id);
          } else {
          }

          if (!shop.shipping_address) {
            addressIDs.shipping_address_id = null;
            await crud.data.create({entity: 'address', item: shippingAddr})
              .then(shippingResult => addressIDs.shipping_address_id = shippingResult.id);
          } else if (shop.shipping_address_id) {
            await crud.data.update({entity: 'address', id: shop.shipping_address_id, update: shop.shipping_address});
            addressIDs.shipping_address_id = shop.shipping_address_id;
          } else if (shippingAddr.name || shippingAddr.street || shippingAddr.postal_code || shippingAddr.city) {
            shop.shipping_address = {...shop.shipping_address, owner_id: customerId};
            await crud.data.create({entity: 'address', item: shop.shipping_address})
              .then(shippingResult => addressIDs.shipping_address_id = shippingResult.id);
          }
          resolve(addressIDs);
        });
      } else {
        return ('');
      }
    }

    if (values.id) {
      crud.data.read({ entity: "user", filter: {property: "email", operator: "equals", value: values.email}})
        .then((check) => {
          if (!check || check.email === userProps.email) {
            crud.data.update({entity: 'user', id: values.id, update: values})
              .then(() => setSuccessMessage('geändert'))
              .catch(errors => setErrors(errors));
          } else {
            return selectCustomer(user.id);
          }
        })
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      customerId = selectCustomer(values.email);
    }

    uploadAddresses(shop, values)
      .then((result) => {
        let item = {
          customer_number: values.customer_number,
          abbreviation: values.abbreviation,
          class: values.class,
          invoice_address_id: result.invoice_address_id,
          shipping_address_id: result.shipping_address_id,
          owner_id: userProps.id || ownerId
        };
        if (shop.id) {
          crud.data.update({entity: 'point_of_sale', id: shop.id, update: item})
            .catch(errors => {
              setErrors(errors);
              withoutErrors = false;
            });
        } else {
          crud.data.create({entity: 'point_of_sale', item: item})
            .catch(errors => {
              setErrors(errors);
              withoutErrors = false;
            });
        }
      })
      .catch(errors => {
        setErrors(errors);
        withoutErrors = false;
      })
      .finally(() => {
        if (withoutErrors){
          setSuccessMessage('erstellt');
        }
      });
  };

  useEffect(() => {
    if (shop.invoice_address && shop.shipping_address) {
      setCopyInvoiceAddress(false);
      if (shop.invoice_address.name === shop.shipping_address.name &&
        shop.invoice_address.city === shop.shipping_address.city &&
        shop.invoice_address.street === shop.shipping_address.street &&
        shop.invoice_address.postal_code === shop.shipping_address.postal_code) {
        setCopyInvoiceAddress(true);
      }
    }
  }, [shop.invoice_address, shop.shipping_address]);

  return (

    <Modal className="EditShopModal" onDismiss={onDismiss}
      title={shop.id ? "Geschäft Bearbeiten" : "Geschäft Anlegen"}>
      <Formik
        initialValues={{
          email: '',
          customer_number: shop.customer_number ? shop.customer_number : '',
          abbreviation: shop.abbreviation ? shop.abbreviation : '',
          class: shop.class ? shop.class : '',
          invoice_name: shop.invoice_address?.name ? shop.invoice_address?.name : '',
          invoice_street: shop.invoice_address?.street ? shop.invoice_address?.street : '',
          invoice_postal_code: shop.invoice_address?.postal_code ?
            shop.invoice_address?.postal_code : '',
          invoice_city: shop.invoice_address?.city ? shop.invoice_address?.city : '',
          shipping_name: shop.shipping_address?.name ? shop.shipping_address?.name : '',
          shipping_street: shop.shipping_address?.street ? shop.shipping_address?.street : '',
          shipping_postal_code: shop.shipping_address?.postal_code ?
            shop.shipping_address?.postal_code : '',
          shipping_city: shop.shipping_address?.city ? shop.shipping_address?.city : '',
          index : shop.index ? shop.index : ''
        }}
        validationSchema={posvalidate}
        onSubmit={submit} validate={() => {
          setErrors([]);
        }}
      >
        {() => (
          <Form>
            <div className="POS-create">
              <Row columns={{default: 1, lg: 2}} gap='1'>
                {!params.id  && !shop.owner_id && <TextInput name="email" label="Kunden E-Mail" id="email" required/>}
              </Row>
              <Row>
                <TextInput name="customer_number" label="Kundennummer" required/>
                <TextInput name="abbreviation" label="Abkürzung"/>
                <TextInput name="class" label="Klasse"/>
              </Row>
              <Row columns={{default: 1, lg: 2}} gap='1'>
                <div>
                  {copyInvoiceAddress && <h3>Rechnungsadresse & Lieferadresse</h3>}
                  {!copyInvoiceAddress && <h3>Rechnungsadresse</h3>}
                  <TextInput name="invoice_name" label="Bezeichnung" required/>
                  <TextInput name="invoice_street" label="Straße" required/>
                  <Row columns={{default: 1, lg: 2}} gap='1'>
                    <TextInput name="invoice_postal_code" label="Postleitzahl" required/>
                    <TextInput name="invoice_city" label="Stadt" required/>
                  </Row>
                  <input type="checkbox" id="copyAddress" checked={!copyInvoiceAddress}
                    onChange={event => setCopyInvoiceAddress(!event.target.checked)}/>
                  <label htmlFor="copyAddress">abweichende Lieferadresse verwenden</label>
                </div>
                <div>
                  {
                    !copyInvoiceAddress && 
                    <div>
                      <h3>Lieferadresse</h3>
                      <TextInput name="shipping_name" label="Bezeichnung" required/>
                      <TextInput name="shipping_street" label="Straße" required/>
                      <Row columns={{default: 1, lg: 2}} gap='1'>
                        <TextInput name="shipping_postal_code" label="Postleitzahl" required/>
                        <TextInput name="shipping_city" label="Stadt" required/>
                      </Row>
                    </div>}
                </div>
              </Row>
            </div>
            <Row>
              <Button inverted onClick={onDismiss} text="Abbrechen"/>
              <Button type="submit" text="speichern"/>
            </Row>
          </Form>
        )}
      </Formik>
      {
        successMessage.length > 0 &&
        <Modal className={"SuccessMessage"} title={"Änderung gespeichert"}
          onDismiss={onDismiss}>
          <h2>Erfolgreich {successMessage}</h2>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Modal>
  );
}
