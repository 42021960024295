import "./accordion.scss";
import Row from "./row";
import {useLayoutEffect, useRef} from "react";
import IconAndText from "./icon-and-text";
import {EmptyPackage} from "./icons";

export default function Accordion({children, open, rowNames, noDataRowNames}) {

  const bodyRef = useRef();

  useLayoutEffect(() => {
    bodyRef.current.style.maxHeight = open ? (bodyRef.current.children[0].offsetHeight + 54) + 'px' : 0;
  }, [open]);

  return (
    <div ref={bodyRef} className="Accordion">
      {
        children.length > 0 &&
        <div>
          {
            rowNames &&
            <Row className="names" columns={{default: rowNames.length}}>
              {rowNames.map((rowName, index) => <span key={index}>{rowName}</span>)}
            </Row>
          }
          {children.map((child, index) => <div className="item" key={index}>{child}</div>)}
        </div>
      }
      {
        children.length <= 0 &&
        <div>
          <Row className="names" columns={{default: noDataRowNames ? noDataRowNames.length + 1 : 1}}>
            <IconAndText icon={<EmptyPackage/>} text="Keine Einträge vorhanden." align="left"/>
            {
              noDataRowNames &&
              noDataRowNames.map((rowName, index) => <span key={index}>{rowName}</span>)
            }
          </Row>
        </div>
      }
    </div>
  );

}
