import {NavLink, useLocation} from "react-router-dom";
import React, {useState} from "react";
import "./navigation.scss";
import {Chevron} from "./icons";
import "./icons.scss";

export function NavItem({title, icon, link, hidden}) {
  return (
    <nav className="nav-item">
      <div>
        <NavLink to={link}>
          {icon && <div title={title} className="nav-icon">{icon}</div>}
          {!hidden && <span>{title}</span>}
        </NavLink>
      </div>
    </nav>
  );
}

export default function NavGroup({ title, icon, children, hidden}) {
  const [isExpanded, setIsExpanded] = useState(hidden);
  const location = useLocation();

  if (hidden && isExpanded) {
    setIsExpanded(false);
  }

  const getActiveNavGroup = () => {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (child.props.to === location.pathname) {
        return true;
      }
    }
    return false;
  };

  const isActive = getActiveNavGroup();

  return (
    <nav className={`nav-group${isActive ? ' active' : ''}`}>
      <div className="nav-group-header" onClick={() => setIsExpanded(!isExpanded)}>
        {icon && <div title={title} className="nav-icon">{icon}</div>}
        {!hidden &&
          <>
            <span>{title}</span>
            <div className="nav-group-chevron">
              <Chevron orientation={isExpanded ? 'up' : 'down'}/>
            </div>
          </>
        }
      </div>
      {isExpanded &&
        <div className="nav-group-body">
          {children}
        </div>
      }
    </nav>
  );
}
