import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Check, Edit, File, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import Row from "../components/row";
import EditModal from "../components/edit-modal";
import * as Yup from "yup";
import TextInput from "../components/text-input";
import DropzoneInput from "../components/DropzoneInput";
import Dropdown from "../components/dropdown";
import DropdownItem from "../components/dropdown-item";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import Checkbox from "../components/checkbox";

export default function Downloads() {

  const pageSize = 10;

  const crud = useCRUD();

  const [downloads, setDownloads] = useState([]);
  const [downloadCategories, setDownloadCategories] = useState([]);
  const [totalDownloads, setTotalDownloads] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Name', orderBy: 'name', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const modalValidationSchema = Yup.object({
    name: Yup.string()
      .required('Bitte eine Bezeichnung angeben'),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      ),
    file: Yup.mixed()
      .required('Bitte laden Sie eine Datei hoch')
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
      .test('type', 'Erlaubte Bildformate: ZIP, PDF, PNG und JPG', val => [
        'application/zip',
        'application/pdf',
        'image/png',
        'image/jpeg'
      ].includes(val ? val.type : '')
      )
  });
  
  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    Promise.all([
      crud.data.bulk.read({entity: 'download', page, page_size: pageSize, order_by: order.orderBy, order: order.order,
        filter: filter.length ? crud.filter.and(...filter) : {}}),
      crud.data.bulk.read({entity: 'download_category'}),
    ])
      .then(async ([downloadResult, downloadCategoryResult]) => {
        setDownloads(await crud.expand(downloadResult.items, ['download_category', 'file', 'image:file']));
        setTotalDownloads(downloadResult.total);
        setDownloadCategories(downloadCategoryResult.items);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{title: 'Bild'}}/>,
    <TableHead property={{title: 'Datei'}}/>,
    <TableHead property={{name: 'name', title: 'Name'}} sortable filterable/>,
    <TableHead
      property={{name: 'download_category_id', title: 'Kategorie'}} sortable filterable type="dropdown"
      dropdownList={downloadCategories} dropdownColumn="name" dropdownDatabaseEntry="id"
    />,
    <TableHead property={{name: 'is_sales', title: 'Handel'}} sortable titleCenter/>,
    <TableHead property={{name: 'is_field_sales', title: 'Außendienst'}} sortable titleCenter/>,
    <TableHead property={{name: 'is_online_sales', title: 'Onlinehandel'}} sortable titleCenter/>,
    <TableHead titleRight resetFilter/>
  ];

  return (
    <Screen className="DownloadPage">
      <h1 className="text-center">Downloads</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Download anlegen" onClick={() => {
            setSelectedDownload(
              {
                name: '',
                download_category_id: 1,
                image: '',
                file: '',
                is_sales: '0',
                is_field_sales: '0',
                is_online_sales: '0'
              }
            );}} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="downloads-table">
        {
          loading && showLoading &&
            <tr>
              <td className="col-span">
                <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
              </td>
            </tr>
        }
        {
          !showLoading && totalDownloads === 0 &&
            <tr>
              <td className="col-span">
                <h2>Keine Einträge vorhanden.</h2>
              </td>
            </tr>
        }
        {
          !showLoading && downloads.length > 0 &&
            <>
              {downloads.map((download, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td>
                    <div className="TeaserImage">
                      {
                        download.image &&
                        <img src={download.image.url} alt={download.image.filename}/>
                      }
                      {
                        !download.image &&
                        <File/>
                      }
                    </div>
                  </td>
                  <td>
                    <AutoTitle>{download.file?.filename ?? ''}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{download.name ?? ''}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{download.download_category.name ?? ''}</AutoTitle>
                  </td>
                  <td className="text-center">
                    <AutoTitle>{download.is_sales === '1' ? <Check/> : ''}</AutoTitle>
                  </td>
                  <td className="text-center">
                    <AutoTitle>{download.is_field_sales === '1' ? <Check/> : ''}</AutoTitle>
                  </td>
                  <td className="text-center">
                    <AutoTitle>{download.is_online_sales === '1' ? <Check/> : ''}</AutoTitle>
                  </td>
                  <td className="text-right icon-row">
                    <div onClick={() => setSelectedDownload(download)} title="Download bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>)}
            </>
        }
      </Table>
      {
        !loading && totalDownloads > downloads.length &&
        <Pagination className="mt-2" total={totalDownloads} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {
        !loading && downloads.length > 0 && selectedDownload &&
        <EditModal title="Download" itemId={selectedDownload.id ?? null} validationSchema={modalValidationSchema}
          databaseName="download" alignBottom
          onDismiss={() => {
            setSelectedDownload(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}>
          <div>
            <TextInput value={selectedDownload.name} name="name" label="Name" required/>
          </div>
          <div>
            <Dropdown className="mb-error" name="download_category_id" label="Kategorie"
              value={selectedDownload?.download_category_id}>
              {
                downloadCategories.map((category, index) => (
                  <DropdownItem databaseEntry={category.id} key={index}>
                    {category.name}
                  </DropdownItem>
                ))
              }
            </Dropdown>
            <Checkbox value={parseInt(selectedDownload.is_sales)} name="is_sales">Handel</Checkbox>
            <Checkbox value={parseInt(selectedDownload.is_field_sales)} name="is_field_sales">Außendienst</Checkbox>
            <Checkbox value={parseInt(selectedDownload.is_online_sales)} name="is_online_sales">Onlinehandel</Checkbox>
          </div>
          <div>
            { (selectedDownload?.image || selectedDownload?.image?.url) &&
            <img
              src={selectedDownload.image && selectedDownload.image.url}
              alt={selectedDownload.image && selectedDownload.image.filename}
            />
            }
            <DropzoneInput name="image" label="Vorschaubild"
              value={
                selectedDownload?.image ? 
                  {
                    ...selectedDownload.image,
                    name: selectedDownload.image.filename,
                    type: selectedDownload.image.media_type
                  } : 
                  null
              }/>
          </div>
          <div>
            {selectedDownload.file && <File/>}
            <DropzoneInput name="file" label="Downloaddatei" required
              value={
                selectedDownload?.file ?
                  {
                    ...selectedDownload.file,
                    name: selectedDownload.file.filename,
                    type: selectedDownload.file.media_type
                  } :
                  null
              }/>
          </div>
        </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}

