import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Edit, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import Row from "../components/row";
import EditModal from "../components/edit-modal";
import TextInput from "../components/text-input";
import * as Yup from "yup";
import DropdownItem from "../components/dropdown-item";
import Dropdown from "../components/dropdown";
import DateTimeInput from "../components/date-time-input";
import {displayPrice, toDateString} from "../functions";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";

export default function Gutscheine() {

  const pageSize = 10;

  const crud = useCRUD();

  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [totalCoupons, setTotalCoupons] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Gutscheincode', orderBy: 'code', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const modalValidationSchema = Yup.object({
    code: Yup.string()
      .required('Bitte einen Gutscheincode angeben'),
    discount_value: Yup.string()
      .required('Bitte einen Betrag angeben')
      .ensure().when('discount_unit', {
        is: 'percent',
        then: Yup.string().matches(/^([1-9][0-9]?|100)$/, "Bitte den Betrag im Format '1-100' angeben"),
        otherwise: Yup.string().matches(/^[0-9]\d*(,\d{1,2})?$/, "Bitte den Betrag im Format '1,00' angeben")
      }),
    validity: Yup.date()
      .required()
      .typeError("Bitte wählen Sie ein gültiges Datum aus")
      .min(new Date(), 'Bitte wählen Sie ein zukünftiges Datum aus')
  });

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read({entity: 'coupon', page, page_size: pageSize, order_by: order.orderBy, order: order.order,
      filter: filter.length ? crud.filter.and(...filter) : {}})
      .then(async (couponResult) => {
        setCoupons(couponResult.items);
        setTotalCoupons(couponResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{name: 'code', title: 'Gutscheincode'}} sortable filterable/>,
    <TableHead property={{name: 'description', title: 'Beschreibung'}} sortable filterable/>,
    <TableHead property={{name: 'discount_value', title: 'Betrag'}} sortable titleRight/>,
    <TableHead
      property={{name: 'discount_unit', title: 'Einheit'}} filterable type="dropdown"
      dropdownList={[{discount_unit: 'cent', display: '€'},{discount_unit: 'percent', display: '%'}]}
      dropdownColumn="display" dropdownDatabaseEntry="discount_unit"
    />,
    <TableHead property={{name: 'validity', title: 'Gültig bis'}} sortable filterable type="date"/>,
    <TableHead resetFilter titleRight/>
  ];

  return (
    <Screen className="CouponPage">
      <h1 className="text-center">Gutscheine</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Gutschein anlegen" onClick={() => {
            setSelectedCoupon(
              {
                name: '',
                description: '',
                discount_value: '',
                discount_unit: 'cent',
              }
            );
          }} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="coupons-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalCoupons === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && coupons.length > 0 &&
            <>
              {coupons.map((coupon, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td>
                    <AutoTitle>{coupon.code && coupon.code}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{coupon.description && coupon.description}</AutoTitle>
                  </td>
                  <td className="text-right"><AutoTitle>{
                    coupon?.discount_unit === 'percent' ?
                      coupon?.discount_value :
                      displayPrice(coupon?.discount_value).slice(0, -2)
                  }</AutoTitle></td>
                  <td>{coupon.discount_unit === 'percent' ? '%' : '€'}</td>
                  <td><AutoTitle>{
                    coupon.validity && new Date(coupon.validity)
                      .toLocaleString('de-DE', {
                        timeZone: 'Europe/Berlin',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })
                  }</AutoTitle></td>
                  <td className='text-right icon-row'>
                    <div onClick={() => setSelectedCoupon(coupon)} title="Gutschein bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>)}
            </>
        }
      </Table>
      {
        !loading && totalCoupons > coupons.length &&
        <Pagination className="mt-2" total={totalCoupons} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {
        !loading && selectedCoupon &&
        <EditModal title="Gutschein" itemId={selectedCoupon.id ?? null} validationSchema={modalValidationSchema}
          databaseName="coupon"
          onDismiss={() => {
            setSelectedCoupon(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}>
          <div>
            <TextInput value={selectedCoupon.code} name="code" label="Gutscheincode" required/>
            <TextInput value={selectedCoupon.description} name="description" label="Beschreibung"/>
            <TextInput value={selectedCoupon.discount_value} name="discount_value" label="Betrag" required/>
            <Dropdown name="discount_unit" label="Einheit" value={selectedCoupon.discount_unit}>
              <DropdownItem databaseEntry="cent">{'€'}</DropdownItem>
              <DropdownItem databaseEntry="percent">{'%'}</DropdownItem>
            </Dropdown>
            <DateTimeInput value={toDateString(new Date(selectedCoupon.validity))} required
              name="validity" label="Gültig bis"/>
          </div>
        </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
