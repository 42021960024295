import Modal from "./modal";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import Button from "./button";
import {Loader} from "./icons";
import Row from "./row";
import ErrorModal from "./error-modal";
import useClaims from "../hook/useClaims";
import useCRUD from "../hook/useCRUD";
import TextInput from "./text-input";

export default function ChangeProfile({onDismiss}) {

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const crud = useCRUD();
  const claims = useClaims();

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Bitte geben Sie einen Vornamen ein'),
    last_name: Yup.string().required('Bitte geben Sie einen Nachnamen ein'),
    email: Yup.string().required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte eine gültige E-Mail-Adresse eingeben')
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'user', id: user.id, update: {...values}})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    crud.data.read({entity: 'user', id: claims.user_id})
      .then((user) => {
        setUser(user);
      })
      .catch(errors => setErrors(errors))
      .finally();
  }, [claims.user_id, crud]);

  return (
    <Modal title="Profil ändern" onDismiss={onDismiss}>
      {
        !showSuccess && user &&
        <>
          <Formik
            initialValues={{first_name: user.first_name, last_name: user.last_name, email: user.email}}
            validationSchema={validationSchema} onSubmit={submit} validate={() => setErrors([])}
          >
            {({isSubmitting}) => (
              <Form>
                <TextInput name="first_name" label="Vorname"/>
                <TextInput name="last_name" label="Nachname"/>
                <TextInput name="email" label="E-Mail-Adresse"/>
                <Row className="mt-1" gap="1" justify="end">
                  <Button text="Abbrechen" onClick={onDismiss} inverted inline/>
                  <Button
                    text="Speichern" type="submit" icon={isSubmitting ? <Loader/> : null} disabled={isSubmitting}
                    inline iconPosition="right"
                  />
                </Row>
              </Form>
            )}
          </Formik>
          <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        </>
      }
      {
        showSuccess &&
        <>
          <h3>Ihr Profil wurde aktualisiert.</h3>
          <p className="text-right"><Button text="Ok" inline onClick={() => {window.location.reload();}}/></p>
        </>
      }
    </Modal>
  );
}
