import "./table.scss";
import React from "react";

export default function Table({children, className, headEntries, order, setOrder, filter, setFilter}) {
  return <table className={"Table " + className}>
    <thead>
      <tr>
        {
          headEntries.map((entry, index) => (
            <th key={index}>
              {
                React.cloneElement(
                  entry,
                  {filter, setFilter, order, setOrder}
                )
              }
            </th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {children}
    </tbody>
  </table>;
}
