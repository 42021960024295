import Modal from "./modal";
import ErrorModal from "./error-modal";
import {useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import TextInput from "./text-input";
import Button from "./button";
import Row from "./row";
import useCRUD from "../hook/useCRUD";

export default function PromotionFactEdit({fact, onDismiss}) {

  const [errors, setErrors] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const crud = useCRUD();

  const validationSchema = Yup.object({
    fact: Yup.string()
      .required('Bitte eine Bezeichnung angeben')
  });

  const submit = (values, {setSubmitting}) => {
    setSubmitting(true);
    let insert = {
      id: values.id,
      promotion_id: fact.promotion_id,
      fact: values.fact
    };
    if (values.id) {
      crud.data.update({entity: 'promotion_fact', id: values.id, update: insert})
        .then(() => setSuccessMessage('geändert'))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      crud.data.create({entity: 'promotion_fact', item: insert})
        .then(() => setSuccessMessage('erstellt'))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    }
  };
  const deleteFact = (fact, {setSubmitting}) => {
    crud.data.delete({entity: 'promotion_fact', id: parseInt(fact.id)})
      .then(() => {
        setShowDeleteConfirm(false);
        setSuccessMessage('gelöscht');
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Modal className={"FactEdit"} title={fact.id ? "Fakt Bearbeiten" : "Fakt Anlegen"}
        onDismiss={onDismiss}>
        <Formik
          initialValues={{id: fact.id, fact: fact.fact}}
          validationSchema={validationSchema}
          onSubmit={submit} validate={() => setErrors([])}
        >
          {() => (
            <Form>
              <TextInput name="fact"/>
              <Row>
                <Button inverted onClick={onDismiss} text="Abbrechen"/>
                {fact.id && <Button color="danger" onClick={() => setShowDeleteConfirm(fact.id)}
                  text="Löschen"/>}
                <Button type="submit" text="Speichern"/></Row>
            </Form>
          )}
        </Formik>
        {showDeleteConfirm &&
        <Modal className={"DeleteConfirm"} title="Fakt Löschen" onDismiss={() => setShowDeleteConfirm(false)}>
          <h2>Bitte bestätigen Sie das Löschen dieses Fakts</h2>
          <Formik initialValues={{id: showDeleteConfirm}} validationSchema={Yup.object({
            id: Yup.string()
              .required('Bitte eine ID eingeben')
          })}
          onSubmit={deleteFact} validate={() => setErrors([])}>
            {() => (
              <Form>
                <Row>
                  <Button inverted onClick={() => setShowDeleteConfirm(false)} text="Abbrechen"/>
                  <Button type="submit" text="Löschen"/>
                </Row>
              </Form>)}
          </Formik>
        </Modal>}
        {successMessage.length > 0 &&
        <Modal className={"SuccessMessage"} title="Fakt geändert"
          onDismiss={onDismiss}>
          <h2>Der Fakt wurde {successMessage}</h2>
          <p className="text-right"><Button text="Ok" onClick={onDismiss} inline/></p>
        </Modal>}
      </Modal>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>);
}
