import "./line-chart.scss";
import {
  Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale
} from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { Line } from 'react-chartjs-2';
import {displayPrice} from "../functions";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

export default function LineChart({datasets, timeUnit, className}) {
  return (
    <div className="LineChart">
      <Line datasetIdKey='id' className={className}
        options={{
          locale: 'de-DE',
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: timeUnit,
                tooltipFormat: 'DD.MM.YYYY',
                displayFormats: {
                  day: 'DD.MM.YYYY',
                  month: 'MM/YY',
                  quarter: 'MM/YY',
                  year: 'YYYY'
                },
              },
            },
            ySalesVolume: {
              type: "linear",
              display: !!datasets.some(dataset => dataset.yAxisID === 'ySalesVolume'),
              position: "left",
              title: {
                display: true,
                text: 'Umsatz'
              },
              beginAtZero: true,
              ticks: {
                count: 11,
                callback: function (value) {
                  return displayPrice(value * 100);
                }
              }
            },
            yProductAndOrder: {
              type: "linear",
              display: !!datasets.some(dataset => dataset.yAxisID === 'yProductAndOrder'),
              position: "right",
              beginAtZero: true,
              title: {
                display: true,
                text: 'Produkte und Bestellungen'
              },
              ticks: {
                count: 11
              }
            }
          }
        }}
        data={{
          datasets: datasets
        }}/>
    </div>
  );
}
