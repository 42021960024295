import "./screen.scss";
import {useEffect, useState} from "react";

export default function Screen({children, horizontalCenter = false, className}) {

  const [isMobile, setIsMobile] = useState((window.innerWidth < 576));

  useEffect(() => {
    function handleResize() {(window.innerWidth < 576) ? setIsMobile(true) : setIsMobile(false);}
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className={'Screen' + (className ? ' ' + className : '') + (horizontalCenter ? ' horizontal-center' : '') +
      (isMobile ? ' responsive' : '')}>
      {children}
    </div>
  );
}
