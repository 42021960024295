import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Edit, Loader} from "../components/icons";
import Table from "../components/table";
import Pagination from "../components/pagination";
import ErrorModal from "../components/error-modal";
import Row from "../components/row";
import Button from "../components/button";
import AdminEdit from "../components/admin-edit";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";

export default function Admin() {

  const pageSize = 10;

  const crud = useCRUD();

  const [admin, setAdmin] = useState([]);
  const [totalUser, setTotalUser] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Benutzername', orderBy: 'username', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAdminEdit, setShowAdminEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read({
      entity: 'user', page, page_size: pageSize, order_by: order.orderBy, order: order.order,
      filter: filter.length ? crud.filter.and(...filter, crud.filter.equals('is_admin', 1)) :
        crud.filter.equals('is_admin', 1)
    })
      .then(async resultAdmin => {
        setAdmin(resultAdmin.items);
        setTotalUser(resultAdmin.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{name: 'username', title: 'Benutzername'}} sortable filterable/>,
    <TableHead property={{name: 'email', title: 'E-Mail'}} sortable filterable/>,
    <TableHead property={{name: 'first_name', title: 'Vorname'}} sortable filterable/>,
    <TableHead property={{name: 'last_name', title: 'Nachname'}} sortable filterable/>,
    <TableHead resetFilter titleRight/>
  ];

  return (
    <Screen className="AdminPage">
      <h1 className="text-center">Shop-Administratoren</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Admin anlegen" onClick={() => setShowAdminEdit({id : null, username: ' '})} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="admins-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalUser === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && admin.length > 0 &&
            <>
              {admin.map((admin, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td><AutoTitle>{admin.username}</AutoTitle></td>
                  <td><AutoTitle>{admin.email}</AutoTitle></td>
                  <td><AutoTitle>{admin.first_name}</AutoTitle></td>
                  <td><AutoTitle>{admin.last_name}</AutoTitle></td>
                  <td className='text-right icon-row'>
                    <div onClick={() => setShowAdminEdit(admin)} title="Admin bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>
              )}
            </>
        }
      </Table>
      {
        !loading && totalUser > admin.length &&
        <Pagination className="mt-2" total={totalUser} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {
        showAdminEdit &&
        <AdminEdit title="Admin bearbeiten" admin={showAdminEdit}
          onDismiss={() => {
            setShowAdminEdit(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}
        />
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
