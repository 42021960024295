import config from "../config.json";
import {useContext, useMemo} from "react";
import {AuthContext} from "../context/auth-context";

export default function useCRUD() {

  const authContext = useContext(AuthContext);

  return useMemo(() => {

    const call = (remoteFunction, remoteArguments) => {
      let url = config.CRUD_URL + '/' + remoteFunction.replaceAll('/', '.');
      if ('entity' in remoteArguments) {
        url += '.' + remoteArguments.entity;
      }
      return new Promise((resolve, reject) => {
        let body = {
          function: remoteFunction,
          parameters: remoteArguments
        };
        if (config.CRUD_TENANT) {
          body.tenant = config.CRUD_TENANT;
        }
        if (authContext.authToken) {
          body.auth = authContext.authToken;
        }
        fetch(
          url,
          {method: 'POST', body: JSON.stringify(body)})
          .then(response => {
            if (response.status !== 200) {
              reject([{message: 'Interner Fehler im CRUD-System.'}]);
            } else {
              response.json()
                .then(result => {
                  if ('errors' in result && result.errors.length > 0) {
                    reject(result.errors);
                  } else {
                    resolve(result.result);
                  }
                })
                .catch(() => {
                  return [{message: 'Fehler beim Verarbeiten der Antwort aus dem CRUD-System.'}];
                });
            }
          })
          .catch(error => {
            console.error(error);
            reject([{
              message: 'Die Verbindung zum CRUD-System kann nicht hergestellt werden.'
            }]);
          });
      });
    };

    const crud = {
      data: {
        create: ({entity, item}) =>
          call('data/create', {entity, item}),
        read: ({entity, id = 0, filter = {}} = {}) =>
          call('data/read', {entity, id, filter}),
        update: ({entity, id = 0, filter = {}, update = {}, validate = {}} = {}) =>
          call('data/update', {entity, id, filter, update, validate}),
        delete: ({entity, id = 0, filter = {}} = {}) =>
          call('data/delete', {entity, id, filter}),
        bulk: {
          create: ({entity, items}) =>
            call('data/bulk/create', {entity, items}),
          read: ({entity, page = 0, page_size = 10, order_by = 'id', order = 'asc', filter = {}} = {}) =>
            call('data/bulk/read', {entity, page, page_size, order_by, order, filter}),
          update: ({entity, filter = {}, update = {}, validate = {}, transaction = 'all-or-none'} = {}) =>
            call('data/bulk/update', {entity, filter, update, validate, transaction}),
          delete: ({entity, filter = {}, transaction = 'all-or-none'} = {}) =>
            call('data/bulk/delete', {entity, filter, transaction})
        }
      },
      filter: {
        equals: (property, value) => ({operator: 'equals', property, value}),
        notEquals: (property, value) => ({operator: 'notEquals', property, value}),
        greater: (property, value) => ({operator: 'greater', property, value}),
        greaterOrEqual: (property, value) => ({operator: 'greaterOrEqual', property, value}),
        less: (property, value) => ({operator: 'less', property, value}),
        lessOrEqual: (property, value) => ({operator: 'lessOrEqual', property, value}),
        beginsWith: (property, value) => ({operator: 'beginsWith', property, value}),
        isNotNull: property => ({operator: 'isNotNull', property}),
        and: (...components) => ({group: 'and', components}),
        or: (...components) => ({group: 'or', components})
      },
      expand: async (items, properties) => {
        const expandedItems = [];
        if (items.length > 0) {
          const readCalls = [];
          for (const property of properties) {
            const home = property.split(/[:<]/)[0];
            const guest = property.split(/[:<]/).reverse()[0];
            const ids = [
              ...new Set(items.map(item => item[property.includes('<') ? 'id' : `${home}_id`]).filter(id => id))
            ];
            readCalls.push(
              crud.data.bulk.read({
                entity: guest, page_size: property.includes('<') ? 1000 : ids.length,
                filter: crud.filter.or(
                  ...ids.map(
                    id => crud.filter.equals(property.includes('<') ?
                      `${guest.replace(new RegExp('_' + home + '$'), '_id')}` : 'id', id)
                  )
                )
              })
            );
          }
          const bulkReadResponses = await Promise.all(readCalls);
          for (const item of items) {
            const newItem = {...item};
            for (const [index, property] of properties.entries()) {
              const home = property.split(/[:<]/)[0];
              const guest = property.split(/[:<]/).reverse()[0];
              if (property.includes('<')) {
                newItem[home] = bulkReadResponses[index].items.filter(
                  x => x[`${guest.replace(new RegExp('_' + home + '$'), '_id')}`] === item.id
                );
              } else {
                const expansion = bulkReadResponses[index].items.find(x => x.id === item[`${home}_id`]);
                expansion && (newItem[home] = expansion);
              }
            }
            expandedItems.push(newItem);
          }
        }
        return expandedItems;
      }
    };

    return crud;

  }, [authContext.authToken]);

}
