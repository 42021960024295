import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {Fragment, useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Check, Edit, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import Row from "../components/row";
import * as Yup from "yup";
import EditModal from "../components/edit-modal";
import DropzoneInput from "../components/DropzoneInput";
import Checkbox from "../components/checkbox";
import {TableHead} from "../components/table-head";
import TextInput from "../components/text-input";

export default function Willkommensbild() {

  const pageSize = 10;

  const crud = useCRUD();

  const [welcomePanels, setWelcomePanels] = useState([]);
  const [totalWelcomePanels, setTotalWelcomePanels] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Sichtbar', orderBy: 'visible', order: 'desc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showWelcomePanelEdit, setShowWelcomePanelEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read(
      {entity: 'welcome_panel', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order, filter: filter.length ? crud.filter.and(...filter) : {}})
      .then(async (welcomePanelResult) => {
        setWelcomePanels(await crud.expand(welcomePanelResult.items,
          ['image:file']));
        setTotalWelcomePanels(welcomePanelResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{title: 'Bild'}} titleCenter/>,
    <TableHead property={{name: 'visible', title: 'Sichtbar'}} sortable filterable/>,
    <TableHead resetFilter titleRight/>
  ];

  const modalValidationSchema = Yup.object({
    image: Yup.mixed()
      .required('Es muss ein Bild hochgeladen werden')
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100_000_000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      )
  });

  return (
    <Screen>
      <h1 className="text-center">Willkommensbilder</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neues Willkommensbild anlegen" onClick={() => setShowWelcomePanelEdit({visible: 0})} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="welcome-panel-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalWelcomePanels === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && welcomePanels.length > 0 &&
          <>
            {welcomePanels.map((welcomePanel, index) =>
              <Fragment key={index}>
                <tr className={(index + 1) % 2 === 0 ? 'clickable shaded' : 'clickable'}>
                  <td className="text-center">
                    <div className="TeaserImage">
                      {
                        welcomePanel.image && welcomePanel.image.url &&
                        <img src={welcomePanel.image.url} alt={welcomePanel.image.filename}/>}
                    </div>
                  </td>
                  <td>
                    {welcomePanel.visible === '1' && <Check/>}</td>
                  <td className='text-right icon-row'>
                    <div onClick={() => setShowWelcomePanelEdit(welcomePanel)} title="Willkommensbild bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-span noPadding">
                  </td>
                </tr>
              </Fragment>
            )}
          </>
        }
      </Table>
      {
        !loading && totalWelcomePanels > welcomePanels.length &&
        <Pagination className="mt-2" total={totalWelcomePanels} page={page} pageSize={pageSize} setPage={setPage}/>
      }

      {
        showWelcomePanelEdit &&
        <EditModal
          title="Willkommensbild" itemId={showWelcomePanelEdit.id ?? null} validationSchema={modalValidationSchema}
          databaseName="welcome_panel"
          onDismiss={() => {
            setShowWelcomePanelEdit(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}>
          <div className="image">
            {
              showWelcomePanelEdit.image && showWelcomePanelEdit.image.url &&
              <img src={showWelcomePanelEdit.image.url} alt={showWelcomePanelEdit.image.filename}/>
            }
            <DropzoneInput name="image" label="Bild" required
              value={
                showWelcomePanelEdit?.image ?
                  {
                    ...showWelcomePanelEdit.image,
                    name: showWelcomePanelEdit.image.filename,
                    type: showWelcomePanelEdit.image.media_type
                  } :
                  null
              }/>
            <Checkbox name="visible" value={parseInt(showWelcomePanelEdit.visible)}>Auf Startseite sichtbar</Checkbox>
            <TextInput
              name="link" label="Link" placeholder="https://landewyck.com/de/" className="mt-1"
              value={showWelcomePanelEdit.link}
            />
          </div>
        </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
