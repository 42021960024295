import "./filter.scss";
import {useState} from "react";
import {Form, Formik} from "formik";
import Dropdown from "./dropdown";
import DropdownItem from "./dropdown-item";
import Checkbox from "./checkbox";
import {X} from "./icons";
import TextInput from "./text-input";
import DateTimeInput from "./date-time-input";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";

export default function Filter({
  setFilter, filter, property, type = "text", dropdownList, dropdownColumn, dropdownDatabaseEntry, databaseSearch
}) {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);

  const [initialCheckbox, ] = useState(type === "checkbox" ? () => {
    let initValues = {};
    property.forEach(
      prop => initValues[prop.name + '_filter'] = filter?.find(item => item.property === prop.name)?.value ?? ''
    );
    return initValues;
  } : {});

  const setFilterFunction = async (values) => {
    if (type === "checkbox") {
      let checkboxFilter = [...filter];
      property.forEach(prop => {
        checkboxFilter = checkboxFilter.filter(item => item.property !== prop.name);
        if (values[prop.name + '_filter']) {
          checkboxFilter.push({operator: 'equals', property: prop.name, value: '1'});
        }
      });
      setFilter(checkboxFilter);
    } else {
      if (
        values.textField &&
        type === "text"
      ) {
        if (!filter.filter(item => item.property === property.name)[0]) {
          setFilter([...filter, {operator: 'contains', property: property.name, value: values.textField}]);
        }
        if (
          filter.filter(item => item.property === property.name)[0] &&
          filter.filter(item => item.property === property.name)[0].value !== values.textField
        ) {
          setFilter([
            ...filter.filter(item => item.property !== property.name),
            {operator: 'contains', property: property.name, value: values.textField}
          ]);
        }
      } else if (
        type === "crud-text"
      ) {
        function getIds(resultData) {
          let ids = [];
          return new Promise(resolve => {
            resultData.items.forEach((item) => {
              ids.push(item.id);
            });
            resolve(ids);
          });
        }

        function getIdsOfRows(tableArray, rowIds = null, index = 0) {
          return new Promise(resolve => {
            let tableFilter = [];
            if (rowIds === null) {
              tableFilter.push({
                operator: 'contains', property: tableArray[index]
                  .split(':')[1], value: values.textField
              });
            } else {
              rowIds?.forEach(id => {
                tableFilter.push({operator: 'equals', property: tableArray[index].split(':')[1], value: id});
              });
            }
            if (tableArray.length > index + 1) {
              crud.data.bulk.read({
                entity: tableArray[index].split(':')[0],
                filter: crud.filter.or(...tableFilter)
              })
                .then(result => {
                  getIds(result)
                    .then((resultIds) => {
                      resolve(getIdsOfRows(tableArray, resultIds.length > 0 ? resultIds : [0], index + 1));
                    });
                })
                .catch(errors => setErrors(errors));
            } else {
              resolve(rowIds);
            }
          });
        }
        
        if (values.textField) {
          let filterValues = new Set();
          if (property.inputs) {
            for (const item of property.inputs) {
              let tables = databaseSearch.replace('?', item).split('->');
              filterValues = new Set([...filterValues,...await getIdsOfRows(tables)]);
            }
          } else {
            let tables = databaseSearch.split('->');
            filterValues = new Set([...filterValues,...await getIdsOfRows(tables)]);
          }
          let crudTextFilter = [];
          filterValues.forEach((value) => {
            crudTextFilter.push({operator: 'equals', property: property.name, value: value});
          });
          setFilter([
            ...filter.filter(item => item.identifier !== property.name),
            {group: 'or', components: {...crudTextFilter}, identifier: property.name, inputValue: values.textField}
          ]);
        } else {
          setFilter([...filter.filter(item => item.identifier !== property.name)]);
        }

      } else if (
        (values.priceMinField || values.priceMaxField) &&
        type === "price"
      ) {
        if (!filter.filter(item => item.property === property.name)[0]) {
          setFilter([
            ...filter,
            {
              operator: 'greaterOrEqual',
              property: property.name,
              value: (parseFloat(values.priceMinField.replace(',', '.')) * 100) || 0
            },
            {
              operator: 'lessOrEqual',
              property: property.name,
              value: (parseFloat(values.priceMaxField.replace(',', '.')) * 100) || 999_999_999
            }
          ]);
        }
        if (
          filter.filter(item => item.property === property.name)[0] &&
          filter.filter(
            item =>
              (item.property === property.name) &&
              (item.operator === 'greaterOrEqual')
          )[0]?.value !== values.priceMinField &&
          filter.filter(
            item =>
              (item.property === property.name) &&
              (item.operator === 'lessOrEqual')
          )[0]?.value !== values.priceMaxField
        ) {
          setFilter([
            ...filter.filter(item => item.property !== property.name),
            {
              operator: 'greaterOrEqual',
              property: property.name,
              value: (parseFloat(values.priceMinField.toString().replace(',', '.')) * 100) || 0
            },
            {
              operator: 'lessOrEqual',
              property: property.name,
              value: (parseFloat(values.priceMaxField.toString().replace(',', '.')) * 100) || 999_999_999
            }
          ]);
        }
      } else if (
        values.dropdownField &&
        type === "dropdown"
      ) {
        if (!filter.filter(item => item.property === property.name)[0]) {
          setFilter([...filter, {operator: 'equals', property: property.name, value: values.dropdownField}]);
        }
        if (
          filter.filter(item => item.property === property.name)[0] &&
          filter.filter(item => item.property === property.name)[0].value !== values.dropdownField
        ) {
          setFilter([
            ...filter.filter(item => item.property !== property.name),
            {operator: 'equals', property: property.name, value: values.dropdownField}
          ]);
        }
      } else if (
        values.dateField &&
        type === "date"
      ) {
        if (!filter.filter(item => item.property === property.name)[0]) {
          setFilter([...filter,
            {operator: 'lessOrEqual', property: property.name, value: values.dateField.split('T')[0] + 'T23:59:59'}]);
        }
        if (
          filter.filter(item => item.property === property.name)[0] &&
          filter.filter(item => item.property === property.name)[0].value.split('T')[0] !==
          values.dateField.split('T')[0]
        ) {
          setFilter([
            ...filter.filter(item => item.property !== property.name),
            {operator: 'lessOrEqual', property: property.name, value: values.dateField.split('T')[0] + 'T23:59:59'}
          ]);
        }
      } else if (filter.filter(item => item.property === property.name)[0]) {
        setFilter(filter.filter(item => item.property !== property.name));
      }
    }
  };

  let initialMinPrice =
    (filter?.find(item => (item.property === property.name) && (item.operator === 'greaterOrEqual')
    )?.value ?? 0 / 100) !== 0 ?
      Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        .format((filter?.find(item => (item.property === property.name) && (item.operator === 'greaterOrEqual')
        )?.value ?? 0) / 100).replace('.', '') : '';

  let initialMaxPrice =
    (filter?.find(item => (item.property === property.name) && (item.operator === 'lessOrEqual')
    )?.value ?? 0 / 100) !== 0 ?
      Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        .format((filter?.find(item => (item.property === property.name) && (item.operator === 'lessOrEqual')
        )?.value ?? 0) / 100 ).replace('.', '') : '';

  let initialTextField = filter?.find(item => item.identifier === property.name)?.inputValue;

  return (
    <>
      <div className={"Filter"}>
        <Formik
          initialValues={{
            textField:
            initialTextField ?
              initialTextField :
              filter?.find(item => (item.property === property.name))?.value ?? '',
            priceMinField: initialMinPrice,
            priceMaxField: initialMaxPrice === '9999.999,99' ? '' : initialMaxPrice,
            dropdownField: filter?.find(item => item.property === property.name)?.value ?? null,
            dateField: filter?.find(item => item.property === property.name)?.value ?? '',
            ...initialCheckbox
          }}
          onSubmit={() => {}}
          validate={values => setFilterFunction(values)}
        >
          {({
            handleChange,
            setFieldValue,
            values
          }) => (
            <Form>
              {
                (type === "text" || type === "crud-text") &&
              <TextInput label={property.title} className="text-field" name="textField" onChange={handleChange}
                value={values.textField} icon={<X/>} onIconClick={() => setFieldValue('textField', '', true)}/>
              }
              {
                type === "price" &&
              <div className="price-wrapper">
                <label>{property.title}</label>
                <div>
                  <TextInput
                    label="von"
                    className="text-right text-field"
                    name="priceMinField"
                    onChange={handleChange}
                    value={values.priceMinField}
                    icon={<X/>}
                    onIconClick={() => setFieldValue('priceMinField', '', true)}
                  />
                </div>
                <div>
                  <TextInput
                    label="bis"
                    className="text-right text-field"
                    name="priceMaxField"
                    onChange={handleChange}
                    value={values.priceMaxField}
                    icon={<X/>}
                    onIconClick={() => setFieldValue('priceMaxField', '', true)}
                  />
                </div>
              </div>
              }
              {
                type === "dropdown" &&
              dropdownList &&
              dropdownColumn &&
              <Dropdown label={property.title} name="dropdownField" selectOption>
                {
                  dropdownList.map((item, index) => (
                    <DropdownItem databaseEntry={item[dropdownDatabaseEntry]} key={index}>
                      {item[dropdownColumn]}
                    </DropdownItem>
                  ))
                }
              </Dropdown>
              }
              {
                type === "checkbox" &&
              <div className="checkbox">
                {
                  property.map((item, index) => (
                    <Checkbox key={index} name={item.name + '_filter'}>{item.title}</Checkbox>
                  ))
                }
              </div>
              }
              {
                type === "date" &&
              <div className="date">
                <DateTimeInput name="dateField" label="Datum bis"/>
                <div className="icon" onClick={() => setFieldValue('dateField', '', true)}>
                  <X/>
                </div>
              </div>
              }
            </Form>
          )}
        </Formik>
      
      </div>
      <ErrorModal errors={errors}/>
    </>
  );
}
