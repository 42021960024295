import "./edit-modal.scss";
import Modal from "./modal";
import Row from "./row";
import Button from "./button";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import useFiles from "../hook/useFiles";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import useBL from "../hook/useBL";

export default function EditModal({
  itemId, databaseName, title, onDismiss, children, validationSchema, alignBottom, columns, additionalValues = {}
}) {

  const files = useFiles();
  const crud = useCRUD();
  const bl = useBL();

  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [initialFormValues,] = useState(() => {
    const valueNames = {};
    React.Children.toArray(children).forEach((child) => {
      React.Children.toArray(child.props.children).forEach((childChild) => {
        if (childChild?.props?.name) {
          if ((childChild.props.name === 'price' || childChild.props.name === 'discount_value')
            && childChild.props.value) {
            valueNames[childChild.props.name] =
              Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                .format(childChild.props.value / 100).replace('.', '');
          } else {
            valueNames[childChild.props.name] = childChild.props.value ?? '';
          }
          if (childChild.props.name === 'discount_unit' && childChild.props.value === 'percent') {
            valueNames['discount_value'] = child.props.children
              .find(item => item.props.name === 'discount_value').props.value;
          }
        }
      });
    });
    return valueNames;
  });

  const submit = async (values, {setSubmitting}) => {

    async function uploadImage(image) {
      return new Promise(async resolve => {

        let newImageId;
        await files.upload(image)
          .then(async (result) => {
            let convertImageAttributes = {image_id: result.id};
            if (['promotion', 'welcome_panel'].includes(databaseName)) {
              convertImageAttributes = {...convertImageAttributes, max_resolution: 1280};
            }
            await bl.convert_image(convertImageAttributes)
              .then((convertResult) => {
                newImageId = convertResult;
              })
              .catch((errors) => {
                newImageId = result.id;
                console.info(
                  `Konvertieren fehlgeschlagen. Das Originalbild wurde eingefügt. Error: ${errors[0].message}`
                );
              });
          });

        resolve(newImageId);
      });
    }

    setSubmitting(true);
    let insert = {...values, id: parseInt(itemId)};
    if (insert?.image?.id) {
      insert.image_id = insert.image.id;
    } else if (insert.image && !insert.image_id) {
      await uploadImage(insert.image)
        .then(result => insert.image_id = result)
        .catch(errors => {
          insert.image_id = null;
          setErrors(errors);
        });
    } else if (initialFormValues.image || values.image === '') {
      insert.image_id = null;
    }

    if (insert?.file?.id) {
      insert.file_id = insert.file.id;
    } else if (insert.file && !insert.file_id) {
      await files.upload(insert.file)
        .then((result) => {
          insert.file_id = result.id;
        })
        .catch(errors => {
          insert.file_id = null;
          setErrors(errors);
        });
    } else if (initialFormValues.file || values.file === '') {
      insert.file_id = null;
    }

    if (insert?.placeholder?.id) {
      insert.placeholder_id = insert.placeholder.id;
    } else if (insert.placeholder && !insert.placeholder_id) {
      await files.upload(insert.placeholder)
        .then((result) => {
          insert.placeholder_id = result.id;
        })
        .catch(errors => {
          insert.placeholder_id = null;
          setErrors(errors);
        });
    } else if (initialFormValues.placeholder || values.placeholder === '') {
      insert.placeholder_id = null;
    }

    if (insert?.symbol?.id) {
      insert.symbol_id = insert.symbol.id;
    } else if (insert.symbol && !insert.symbol_id && insert?.symbol?.path?.length > 0) {
      await files.upload(insert.symbol)
        .then((result) => {
          insert.symbol_id = result.id;
        })
        .catch(errors => {
          insert.symbol_id = null;
          setErrors(errors);
        });
    } else if (initialFormValues.symbol || values.symbol === '') {
      insert.symbol_id = null;
    }

    insert = {...insert, ...additionalValues};

    for (const key in insert) {
      if (values[key] && insert[key].length > 0) {
        insert[key] = insert[key].trim();
      }
      if (insert[key] === "") {
        insert[key] = null;
      }
      if (insert[key] === false) {
        insert[key] = 0;
      }
      if (insert[key] === true) {
        insert[key] = 1;
      }
    }

    delete insert.image;
    delete insert.file;
    delete insert.placeholder;
    delete insert.symbol;

    if (insert.price || insert.discount_value) {
      if (insert.discount_unit !== 'percent') {
        let decimalPoint = insert.price ? insert.price : insert.discount_value;
        if (decimalPoint.at(-2) === ',') {
          decimalPoint = decimalPoint.replace(',', '') * 10;
        } else if (!decimalPoint.includes(',')) {
          decimalPoint = decimalPoint * 100;
        } else {
          decimalPoint = decimalPoint.replace(',', '');
        }
        insert.price ? insert.price = decimalPoint : insert.discount_value = decimalPoint;
      }
    } else {
      if (insert.hasOwnProperty('price')) {
        insert.price = 0;
      }
      if (insert.hasOwnProperty('discount_value')) {
        insert.discount_value = null;
      }
    }
    if ((insert.is_sales || insert.is_field_sales) && insert.hasOwnProperty('product_type_id')) {
      insert.price = 0;
    }

    if (itemId) {
      crud.data.update({entity: databaseName, id: insert.id, update: insert})
        .catch(errors => setErrors(errors))
        .finally(() => setSuccessMessage('geändert'));
    } else {
      crud.data.create({entity: databaseName, item: insert})
        .catch(errors => setErrors(errors))
        .finally(() => setSuccessMessage('erstellt'));
    }
    setSubmitting(false);
  };

  const deleteItem = (values, {setSubmitting}) => {
    setSubmitting(true);
    crud.data.delete({entity: databaseName, id: parseInt(itemId)})
      .then(() => setShowDeleteConfirm(false))
      .catch(errors => setErrors(errors))
      .finally(() => {
        setSuccessMessage('gelöscht');
        setSubmitting(false);
      });
  };

  return (
    <Modal className="EditModal" onDismiss={onDismiss} 
      title={title ? (itemId ? `${title} bearbeiten` : `${title} anlegen`) : ''}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema} onSubmit={submit} validate={() => setErrors([])}
      >
        {() => (
          <Form>
            <Row columns={React.Children.toArray(children).length > 1 ? {default: 1, md: columns ? columns : 2} : {}}>
              {
                React.Children.toArray(children).map((child, index) => {
                  return (
                    <div className={'modal-box' + (alignBottom ? ' align-bottom' : '')} key={index}>
                      {
                        React.Children.toArray(child.props.children).map((childChild, index) => {
                          return (
                            <div className="modal-box-element" key={index}>{childChild}</div>
                          );
                        })
                      }
                    </div>
                  );
                })
              }
            </Row>
            <Row>
              <Button inverted onClick={onDismiss} text="Abbrechen"/>
              {
                itemId &&
                  <Button color="danger" onClick={() => setShowDeleteConfirm(true)} text="Löschen" className="Danger"/>
              }
              <Button type="submit" text="Speichern"/>
            </Row>
          </Form>
        )}
      </Formik>
      {
        showDeleteConfirm &&
        <Modal className={"DeleteConfirm"} title= {title && `${title} löschen`}
          onDismiss={() => setShowDeleteConfirm(false)}>
          <h2>Bitte bestätigen Sie das Löschen</h2>
          <Formik initialValues={{id: showDeleteConfirm}} onSubmit={deleteItem}>
            {() => (
              <Form>
                <Row>
                  <Button inverted onClick={() => setShowDeleteConfirm(false)} text="Abbrechen"/>
                  <Button type="submit" text="Löschen"/>
                </Row>
              </Form>)}
          </Formik>
        </Modal>
      }
      {
        successMessage.length > 0 &&
        <Modal className={"SuccessMessage"} title={title}
          onDismiss={onDismiss}>
          <h3>Erfolgreich {successMessage}</h3>
          <p className="text-right"><Button text="OK" onClick={onDismiss} inline/></p>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Modal>
  );
}
