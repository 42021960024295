import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Arrow, Edit, Loader} from "../components/icons";
import Table from "../components/table";
import Pagination from "../components/pagination";
import ErrorModal from "../components/error-modal";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import EditShopModal from "../components/edit-shop-modal";
import Button from "../components/button";
import Row from "../components/row";
import {useNavigate, useParams} from "react-router-dom";

export default function Geschaefte() {

  const pageSize = 10;

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();

  const [pos, setPos] = useState([]);
  const [totalPos, setTotalPos] = useState();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Kundennummer', orderBy: 'customer_number', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showEdit, setShowEdit] = useState();
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    const userFilter = params.id ? crud.filter.equals('owner_id', params.id) : crud.filter.greater('owner_id', 0);

    Promise.all([
      crud.data.bulk.read({
        entity: 'point_of_sale',
        page,
        page_size: pageSize,
        order_by: order.orderBy,
        order: order.order,
        filter: filter.length ? crud.filter.and(...filter, userFilter,
          crud.filter.notEquals('customer_number', 'is_sales'),
          crud.filter.notEquals('customer_number', 'is_field_sales')
        ) : crud.filter.and(userFilter,
          crud.filter.notEquals('customer_number', 'is_sales'),
          crud.filter.notEquals('customer_number', 'is_field_sales')
        )
      }),
      crud.data.bulk.read({entity: 'user', filter: userFilter})
    ])
      .then( async ([posResult, userResult]) => {
        setPos(await crud.expand(
          posResult.items,
          ['invoice_address:address', 'shipping_address:address', 'owner:user']
        ));
        setTotalPos(posResult.total);
        setUsers(userResult.items);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, filter, params.id, reloadKey]);

  const thead = [
    <TableHead property={{name: 'customer_number', title: 'Kundennummer'}} sortable filterable/>,
    <TableHead property={{name: 'abbreviation', title: 'Abkürzung'}} sortable filterable/>,
    <TableHead property={{name: 'owner_id',title: 'Kunden E-Mail'}} filterable type="crud-text"
      databaseSearch="user:email->point_of_sale:owner_id"/>,
    <TableHead filterable type="crud-text" databaseSearch="address:?->point_of_sale:invoice_address_id"
      property={{
        name: 'invoice_address_id',
        title: 'Rechnungsadresse',
        inputs: ['name', 'street', 'postal_code', 'city']
      }}/>,
    <TableHead filterable type="crud-text" databaseSearch="address:?->point_of_sale:shipping_address_id"
      property={{
        name: 'shipping_address_id',
        title: 'Lieferadresse',
        inputs: ['name', 'street', 'postal_code', 'city']
      }}/>,
    <TableHead titleRight resetFilter/>
  ];

  return (
    <Screen className="Geschaefte">
      {
        !showLoading && users[0]?.email && params.id &&
        <h1 className="text-center">{`Geschäfte von ${users[0]?.username || users[0]?.email}`}</h1>
      }
      {
        !params.id &&
        <h1 className="text-center">Geschäfte</h1>
      }
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neue Geschäft anlegen" onClick={() => setShowEdit(true)} inline/>
        </div>
      </Row>
      {
        params.id &&
        <Row columns={{default: 1, lg: 2}} gap='3'>
          <div>
            <Button icon={<Arrow orientation="left"/>} onClick={() => navigate(-1, {replace: true})} text="zurück"
              inline color="background" inverted/>
          </div>
        </Row>
      }
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="shops-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalPos === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && pos.length > 0 &&
          <>
            {pos.map((point, index) =>
              <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                <td><AutoTitle>{point.customer_number}</AutoTitle></td>
                <td><AutoTitle>{point.abbreviation}</AutoTitle></td>
                <td><AutoTitle>{point.owner.email}</AutoTitle></td>
                <td>
                  <div><AutoTitle>{point.invoice_address?.name ?? ''}</AutoTitle></div>
                  <div><AutoTitle>{point.invoice_address?.street ?? ''}</AutoTitle></div>
                  <div><AutoTitle>
                    {(point.invoice_address?.postal_code ?? '') + " " + (point.invoice_address?.city ?? '')}
                  </AutoTitle></div>
                </td>
                <td>
                  <div><AutoTitle>{point.shipping_address?.name ?? ''}</AutoTitle></div>
                  <div><AutoTitle>{point.shipping_address?.street ?? ''}</AutoTitle></div>
                  <div><AutoTitle>
                    {(point.shipping_address?.postal_code ?? '') + " " + (point.shipping_address?.city ?? '')}
                  </AutoTitle></div>
                </td>
                <td className='text-right'>
                  <div onClick={() => setShowEdit(point)} title="Geschäft Bearbeiten">
                    <Edit/>
                  </div>
                </td>
              </tr>
            )}
          </>
        }
      </Table>
      {
        !loading && totalPos > pos.length &&
        <Pagination className="mt-2" total={totalPos} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showEdit &&
        <EditShopModal title="Geschäft bearbeiten" shop={showEdit} databaseName="shop"
          onDismiss={() => {
            setShowEdit(null);
            setReloadKey(reloadKey + 1);
          }}
        >
        </EditShopModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
