import "./table-head.scss";
import React, {useState} from "react";
import {Arrow, Filter as FilterIcon, FilterOff} from "./icons";
import Button from "./button";
import Filter from "./filter";

export function TableHead ({filterable, filter, setFilter, sortable, order, resetFilter, setOrder, property = {}, type,
  dropdownColumn, dropdownList, dropdownDatabaseEntry, titleRight, titleCenter, databaseSearch}) {

  const [showPopup, setShowPopup] = useState(false);

  const getTitle = () => {
    let title = '';
    if (type === 'checkbox') {
      property.forEach((prop) => {
        title += (title !== '') ? " / " + prop.title : prop.title;
      });
    } else {
      title = property.title;
    }
    return title;
  };

  const isFiltered = () => {
    let filtered;
    if (type === 'checkbox') {
      property.forEach((prop) => {
        if (!filtered) {
          filtered = !!filter.find(item => item.property === prop.name);
        }
      });
    } else if (type === 'crud-text') {
      let filterState = false;
      filter.forEach((item) => {
        if (
          Array.isArray(item.components) &&
          Object.values(item.components).filter(component => component.property === property.name).length > 0
        ) {
          filterState = true;
        }
      });
      return filterState;
    } else {
      filtered = filter.find(item => (item.property === property.name));
    }
    return filtered;
  };

  return (
    <div className="TableHead">
      <div className={"title" + (filterable || sortable ? ' clickable' : '') + (titleRight ? ' right' : '') +
      (titleCenter ? ' center' : '')} onClick={() => setShowPopup(true)}>
        <span>{Object.is(property, null) ? '' : getTitle()}</span>
        {
          order.orderBy === property.name &&
          <Arrow orientation={order.order === 'desc' ? 'down' : 'up'}/>
        }
        {
          isFiltered() &&
          !resetFilter &&
          <FilterIcon/>
        }
        {
          resetFilter && (filter.length > 0) &&
          <div className="clickable" title="Filter zurücksetzen" onClick={() => setFilter([])}><FilterOff/></div>
        }
      </div>
      {
        showPopup && (filterable || sortable) &&
        <div className="popup-closer-area" onClick={() => setShowPopup(false)}/>
      }
      {
        showPopup && (filterable || sortable) &&
        <div className="popup">
          {
            sortable &&
            <div>
              <div
                onClick={() => setOrder({order: 'asc', orderBy: property.name})}
                className={"popup-element clickable" +
                ((order.orderBy === property.name && order.order === 'asc') ? ' selected' : '')}
              >
                <Arrow orientation="up"/>
                Aufsteigend&nbsp;sortieren
              </div>
              <div
                onClick={() => setOrder({order: 'desc', orderBy: property.name})}
                className={"popup-element clickable" +
                ((order.orderBy === property.name && order.order === 'desc') ? ' selected' : '')}
              >
                <Arrow orientation="down"/>
                Absteigend&nbsp;sortieren
              </div>
            </div>
          }
          {
            filterable &&
            <Filter
              filter={filter} setFilter={setFilter} property={property} type={type} dropdownList={dropdownList}
              dropdownColumn={dropdownColumn} dropdownDatabaseEntry={dropdownDatabaseEntry}
              databaseSearch={databaseSearch}
            />
          }
          <Button inverted text="Fertig" onClick={() => setShowPopup(false)}/>
        </div>
      }
    </div>
  );
}
