import {useField, useFormikContext} from "formik";
import './text-input.scss';
import Error from "./error";

export default function TextInput({
  id, name, label, type, placeholder, button, icon, onIconClick, required, className, error
}) {

  const [field, meta] = useField(name);
  const {isSubmitting} = useFormikContext();

  return (
    <div
      className={
        'TextInput' + (meta.touched && meta.error ? ' has-error' : '') + (required ? ' required' : '') +
        (className ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id || name}>{label}</label>}
      <div className="wrapper">
        <input id={id || name} type={type} placeholder={placeholder} disabled={isSubmitting} {...field}/>
        {button}
        {icon && <span className= {"icon" + (onIconClick ? " clickable" : "")} onClick={onIconClick}>{icon}</span>}
      </div>
      <Error className="mt-025">{(meta.touched && meta.error) || error}</Error>
    </div>
  );

}
