import './menu.scss';
import {useContext, useEffect, useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from "../context/auth-context";
import useStorage from "../hook/useStorage";
import {Chevron, LogOut, Minus, ShoppingCart, File, User, Users, MapPin, PieChart,} from "../components/icons";
import NavGroup, {NavItem} from "../components/navigation";

export default function Menu() {

  const authContext = useContext(AuthContext);
  const storage = useStorage();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const [isMobile, setIsMobile] = useState((window.innerWidth < 576));
  const location = useLocation();
  useEffect(() => {if (isMobile) {setExpanded(false);}}, [location.pathname, isMobile]);

  useEffect(() => {
    function handleResize() {(window.innerWidth < 576) ? setIsMobile(true) : setIsMobile(false);}
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const logout = () => {
    authContext.setAuthToken('');
    storage.session.remove('authToken');
    storage.local.remove('authToken');
    navigate('/');
  };

  return (
    <div>
      <nav className={(isMobile ? 'MobileMenu' : 'MainMenu') + (expanded ? ' expanded' : '')}>
        <div className="menu-wrapper">
          { isMobile &&
              <div className={expanded ? 'menu-icon-expanded' : 'menu-icon'}
                onClick={() => {setExpanded(!expanded);}}>
                <Minus className="1"/>
                <Minus className="2"/>
                <Minus className="3"/>
              </div>
          }
          <div className="title clickable" onClick={() => navigate('/')}>
            <img src={process.env.PUBLIC_URL + "/landewyck-deutschland.svg"} alt="Logo"/>
            <span>Admin</span>
          </div>
        </div>
        { !isMobile &&
          <div className="expander" title={expanded ? 'Navigationsleiste ausblenden' : 'Navigationsleiste anzeigen'}
            onClick={() => {setExpanded(!expanded);}}>
            <span>
              <Chevron orientation={ !expanded ? 'right' : 'left'}/>
            </span>
          </div>
        }
        { ((isMobile && expanded) || !isMobile ) &&
          <div>
            <div>
              <NavItem title="Dashboard" icon={<PieChart/>} link="/dashboard" hidden={!expanded}/>
              <NavItem title="Profil" icon={<User/>} link="/profil" hidden={!expanded}/>
              <span onClick={() => {setExpanded(true);}}>
                <NavGroup title="Benutzer" icon={<Users/>} hidden={!expanded}>
                  <NavLink to="/kunden"><span>Kunden</span></NavLink>
                  <NavLink to="/admin"><span>Admins</span></NavLink>
                  <NavLink to="/aussendienst"><span>Außendienst</span></NavLink>
                  <NavLink to="/handel"><span>Handel</span></NavLink>
                  <NavLink to="/onlinehandel"><span>Onlinehandel</span></NavLink>
                </NavGroup>
              </span>
              <span onClick={() => {setExpanded(true);}}>
                <NavGroup title="Händler" icon={<MapPin/>} hidden={!expanded}>
                  <NavLink to="/geschaefte"><span>Geschäfte</span></NavLink>
                  <NavLink to="/grosshaendler"><span>Großhändler</span></NavLink>
                </NavGroup>
              </span>
              <span onClick={() => {setExpanded(true);}}>
                <NavGroup title="Shop" icon={<ShoppingCart/>} hidden={!expanded}>
                  <NavLink to="/produkte"><span>Produkte</span></NavLink>
                  <NavLink to="/nr-produkte"><span>Naturalrabatte</span></NavLink>
                  <NavLink to="/aktionen"><span>Aktionen</span></NavLink>
                  <NavLink to="/willkommensbild"><span>Willkommensbild</span></NavLink>
                  <NavLink to="/gutscheine"><span>Gutscheine</span></NavLink>
                  <NavLink to="/marken"><span>Marken</span></NavLink>
                  <NavLink to="/produkttypen"><span>Produkttypen</span></NavLink>
                  <NavLink to="/bestellungen"><span>Bestellungen</span></NavLink>
                </NavGroup>
              </span>
              <NavItem title="Downloads" icon={<File/>} link="/downloads" hidden={!expanded}/>
              <span onClick={logout}>
                <NavItem icon={<LogOut/>} title="Abmelden" link="/abmelden" hidden={!expanded}/>
              </span>
            </div>
          </div>
        }
      </nav>
      {isMobile && expanded && <div className="closer-menu" onClick={() => {setExpanded(false);}}/>}
    </div>
  );
}
