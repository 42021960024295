import Login from "../components/login";
import Screen from "../screen-element/screen";

export default function Anmelden() {
  return (
    <Screen horizontalCenter>
      <h1>Willkommen im Admin Bereich des Landewyck Shop</h1>
      <Login className="mt-2"/>
    </Screen>
  );
}
