import Dropzone from 'react-dropzone';
import './DropzoneInput.scss';
import {useField} from "formik";
import {useState} from "react";
import {Upload, X} from "./icons";
import Error from "./error";

export default function DropzoneInput({label, multiple, infoText, className, required, ...props}) {

  const [, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const [dragOver, setDragOver] = useState(false);

  const onDrop = acceptedFiles => {
    setDragOver(false);
    if (multiple) {
      const newValue = [...value];
      for (const acceptedFile of acceptedFiles) {
        let alreadyThere = false;
        for (const oldValue of value) {
          if (oldValue.name === acceptedFile.name) {
            alreadyThere = true;
          }
        }
        !alreadyThere && newValue.push(acceptedFile);
      }
      setValue(newValue);
    } else {
      setValue(acceptedFiles[0]);
    }
  };

  const removeFile = index => {
    setValue(multiple ? value.filter((_, i) => i !== index) : null);
  };

  return (
    <Dropzone
      onDrop={onDrop} multiple={multiple} onDragEnter={() => setDragOver(true)} onDragLeave={() => setDragOver(false)}
    >
      {({getRootProps, getInputProps}) => (
        <div
          className={
            'DropzoneInput' + (className ? ` ${className}` : '') + (dragOver ? ' drag-over' : '') +
            (required ? ' required' : '') + (meta.touched && meta.error ? ' has-error' : '')
          }
        >
          {label && <label htmlFor={props.id || props.name}>{label}</label>}
          <div className="zone" {...getRootProps()}>
            <input id={props.id || props.name} {...getInputProps()}/>
            <Upload/>
            <span>{props.text || (label && label + ' hochladen') || 'Hochladen'}</span>
            oder Drag and Drop
            {infoText && <div className="info-text">({infoText})</div>}
          </div>
          {
            multiple && value && value.length > 0 &&
            <ul>
              {
                value.map(
                  (file, index) =>
                    <li key={index}>
                      <span>{file.name}</span>
                      <span className="icon" onClick={() => removeFile(index)}><X/></span>
                    </li>
                )
              }
            </ul>
          }
          {!multiple && value && <ul><li>
            <span>{value.name}</span>
            <span  className="icon" onClick={removeFile}><X/></span>
          </li></ul>}
          {!multiple && !value && <span className="placeholder-li"/>}
          <Error className="mt-025">{meta.touched && meta.error}</Error>
        </div>
      )}
    </Dropzone>
  );
}
