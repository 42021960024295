import "./aktionen.scss";
import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {Fragment, useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Check, Chevron, Edit, EmptyPackage, Loader, X} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import {displayPrice} from "../functions";
import Row from "../components/row";
import Accordion from "../components/accordion";
import IconButton from "../components/icon-button";
import PromotionFactEdit from "../components/promotion-fact-edit";
import * as Yup from "yup";
import DropzoneInput from "../components/DropzoneInput";
import TextInput from "../components/text-input";
import Checkbox from "../components/checkbox";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import PromotionHasProductEdit from "../components/promotion-has-product-edit";
import Modal from "../components/modal";
import {Form, Formik} from "formik";
import useFiles from "../hook/useFiles";
import useBL from "../hook/useBL";

export default function Aktionen() {

  const pageSize = 10;

  const crud = useCRUD();
  const files = useFiles();
  const bl = useBL();

  const [promotions, setPromotions] = useState([]);
  const [totalPromotions, setTotalPromotions] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Überschrift', orderBy: 'headline', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showPromotionEdit, setShowPromotionEdit] = useState(null);
  const [showPromotionFactEdit, setShowPromotionFactEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);
  const [open, setOpen] = useState(null);
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState(null);
  const [productsInDatabase, setProductsInDatabase] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read(
      {entity: 'promotion', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order, filter: filter.length ? crud.filter.and(...filter) : {}})
      .then(async (promotionResult) => {
        setPromotions(await crud.expand(promotionResult.items,
          ['image:file', 'fact<promotion_fact', 'has_product<promotion_has_product']));
        setTotalPromotions(promotionResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const setPromotionProducts = product_ids => {
    if (product_ids.length > 0) {
      setLoadingProducts(true);
      crud.data.bulk.read({
        entity: 'product',
        filter: crud.filter.or(...product_ids.map(product_id => crud.filter.equals('id', product_id.id)))
      })
        .then(async resultProducts => {
          let expandedProducts = await crud.expand(resultProducts.items, ['brand']);
          setProducts(expandedProducts.map(expandedProduct =>
            ({...expandedProduct, amount: product_ids.find(item => item.id === expandedProduct.id).amount})
          ));
          if (productsInDatabase === null) {
            setProductsInDatabase(
              resultProducts.items.map(
                product => ({...product, amount: product_ids.find(item => item.id === product.id).amount})
              )
            );
          }
        })
        .catch(error => setErrors(error))
        .finally(() => setLoadingProducts(false));
    } else {
      setProducts([]);
      if (productsInDatabase === null) {
        setProductsInDatabase([]);
      }
    }
  };

  const thead = [
    <TableHead/>,
    <TableHead property={{title: 'Bild'}}/>,
    <TableHead property={{name: 'headline', title: 'Überschrift'}} sortable filterable/>,
    <TableHead property={{name: 'price', title: 'Preis'}} sortable filterable type="price" titleRight/>,
    <TableHead property={[{name: 'is_starred', title: 'Auf Startseite'}]} filterable type="checkbox" titleCenter/>,
    <TableHead property={[{name: 'one_time_order', title: 'Aktion gibt nur 1x Punkte'}]}
      filterable type="checkbox" titleCenter/>,
    <TableHead resetFilter titleRight/>
  ];
  const modalValidationSchema = Yup.object({
    headline: Yup.string()
      .required('Bitte eine Bezeichnung angeben'),
    price: Yup.string()
      .matches(/\d+,\d{2} *€?/, "Bitte den Preis im Format '1,00' angeben"),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100_000_000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      )
  });
  const openAccordion = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const submit = async values => {
    async function uploadImage(image) {
      return new Promise(async resolve => {
        let newImageId;
        await files.upload(image)
          .then(async (result) => {
            await bl.convert_image({image_id: result.id, max_resolution: 1280})
              .then(convertResult => {
                newImageId = convertResult;
              })
              .catch(errors => {
                newImageId = result.id;
                console.info(
                  `Konvertieren fehlgeschlagen. Das Originalbild wurde eingefügt. Error: ${errors[0].message}`
                );
              });
          });

        resolve(newImageId);
      });
    }

    async function saveProducts(productsToSave, promotionId) {
      return new Promise(async resolve => {
        let productIdsInDatabase = productsInDatabase?.map(productInDatabase => ({
          product_id: productInDatabase.id, promotion_id: promotionId, amount: productInDatabase.amount
        })) ?? [];
        let productIdsToSave = productsToSave.map(productToSave => ({
          product_id: productToSave.id, promotion_id: promotionId, amount: productToSave.amount
        }));

        let productsToDelete = productIdsInDatabase.filter(productIdInDatabase =>
          !productIdsToSave.some(productIdToSave => productIdToSave.product_id === productIdInDatabase.product_id));
        let productsToCreate = productIdsToSave.filter(productIdToSave =>
          !productIdsInDatabase.some(productIdInDatabase =>
            productIdInDatabase.product_id === productIdToSave.product_id));
        let productsToUpdate = productIdsToSave.filter(productIdToSave =>
          !productsToCreate.concat(productsToDelete).some(item => item.product_id === productIdToSave.product_id));
        productsToUpdate = productsToUpdate.filter(productToUpdate =>
          !productIdsInDatabase.some(productIdInDatabase => productIdInDatabase.amount === productToUpdate.amount));

        let promotionHasProductCalls = [];
        if (productsToDelete.length > 0) {
          productsToDelete.forEach(productToDelete => promotionHasProductCalls.push(
            crud.data.delete({
              entity: 'promotion_has_product',
              filter: crud.filter.and(
                crud.filter.equals('promotion_id', productToDelete.promotion_id),
                crud.filter.equals('product_id', productToDelete.product_id)
              )
            })
          ));
        }
        if (productsToCreate.length > 0) {
          productsToCreate.forEach(productToCreate => promotionHasProductCalls.push(
            crud.data.create({
              entity: 'promotion_has_product',
              item: productToCreate
            })
          ));
        }
        if (productsToUpdate.length > 0) {
          productsToUpdate.forEach(productToUpdate => promotionHasProductCalls.push(
            crud.data.update({
              entity: 'promotion_has_product',
              filter: crud.filter.and(
                crud.filter.equals('promotion_id', productToUpdate.promotion_id),
                crud.filter.equals('product_id', productToUpdate.product_id)
              ),
              update: {amount: productToUpdate.amount}
            })
          ));
        }
        Promise.all(promotionHasProductCalls)
          .catch(error => setErrors(error))
          .finally(() => {
            setProductsInDatabase(null);
            resolve();
          });
      });
    }

    setSubmitting(true);
    let imageId = values.image_id ?? null;
    if (values.image && !values.image?.url) {
      imageId = await uploadImage(values.image);
    }
    if (values.promotion_id) {
      await saveProducts(products, values.promotion_id);
      crud.data.update({
        entity: 'promotion', id: values.promotion_id, update: {
          headline: values.headline,
          price: parseInt(values.price.replace(/[ .,€]/g, '')),
          credits: values.credits === "" ? null : parseInt(values.credits),
          image_id: imageId,
          is_starred: values.is_starred ? 1 : 0,
          one_time_order: values.one_time_order ? 1 : 0,
          href: values.href
        }
      })
        .then(() => setSuccessMessage('Aktion erfolgreich gespeichert'))
        .catch(error => setErrors(error));
    } else {
      crud.data.create({
        entity: 'promotion', item: {
          headline: values.headline,
          price: parseInt(values.price.replace(/[ .,€]/g, '')),
          image_id: imageId,
          is_starred: values.is_starred ? 1 : 0,
          one_time_order: values.one_time_order ? 1 : 0,
          href: values.href
        }
      })
        .then(async result => {
          let noError = true;
          try {
            await saveProducts(products, result.id);
          }
          catch (error) {
            setErrors(error);
            noError = false;
          }
          if (noError) {
            setSuccessMessage('Aktion erfolgreich gespeichert');
          }
        })
        .catch(error => setErrors(error));
    }
  };

  const doDelete = promotionId => {
    setDeleting(true);
    let deleteCalls = [];
    deleteCalls.push(crud.data.delete({entity: 'promotion', id: promotionId}));
    deleteCalls.push(
      crud.data.bulk.delete({entity: 'promotion_has_product', filter: crud.filter.equals('promotion_id', promotionId)})
    );
    deleteCalls.push(
      crud.data.bulk.delete({entity: 'promotion_fact', filter: crud.filter.equals('promotion_id', promotionId)})
    );
    Promise.all(deleteCalls)
      .catch(error => setErrors(error))
      .then(() => setSuccessMessage('Aktion erfolgreich gelöscht'))
      .finally(() => {
        setShowDeleteConfirm(null);
        setDeleting(false);
      });
  };

  return (
    <Screen>
      <h1 className="text-center">Aktionen</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neue Aktion anlegen" onClick={() => {
            setPromotionProducts([]);
            setShowPromotionEdit({headline: '', price: '', is_starred: 0, one_time_order: 0, href: ''});
          }} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="promotions-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalPromotions === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && promotions.length > 0 &&
            <>
              {promotions.map((promotion, index) =>
                <Fragment key={index}>
                  <tr className={(index + 1) % 2 === 0 ? 'clickable shaded' : 'clickable'}>
                    <td onClick={() => openAccordion(promotion.id)}>
                      <Chevron orientation={promotion.id === open ? "up" : "down"}/>
                    </td>
                    <td onClick={() => openAccordion(promotion.id)}>
                      <div className="TeaserImage">
                        {
                          promotion.image && promotion.image.url &&
                          <img src={promotion.image.url} alt={promotion.image.filename}/>}
                      </div>
                    </td>
                    <td onClick={() => openAccordion(promotion.id)}>
                      <AutoTitle>{promotion.headline && promotion.headline}</AutoTitle>
                    </td>
                    <td onClick={() => openAccordion(promotion.id)} className="text-right padding-right-05">
                      <AutoTitle>{promotion.price && displayPrice(promotion.price)}</AutoTitle>
                    </td>
                    <td onClick={() => openAccordion(promotion.id)} className="text-center">
                      {promotion.is_starred === '1' && <Check/>}</td>
                    <td onClick={() => openAccordion(promotion.id)} className="text-center">
                      {promotion.one_time_order === '1' && <Check/>}</td>
                    <td className='text-right icon-row'>
                      <div title="Aktion bearbeiten" onClick={() => {
                        setPromotionProducts(
                          promotion.has_product.map(item => ({id: item.product_id, amount: item.amount}))
                        );
                        setShowPromotionEdit(promotion);
                      }}>
                        <Edit/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-span noPadding">
                      <Accordion
                        open={promotion.id === open}
                        rowNames={[
                          'Fakten',
                          <div className="text-right">
                            <Button
                              inline text="Fakt hinzufügen"
                              onClick={() => setShowPromotionFactEdit({promotion_id: promotion.id, fact: ''})}
                            />
                          </div>
                        ]}
                        noDataRowNames={[
                          <div className="text-right">
                            <Button
                              inline text="Fakt hinzufügen"
                              onClick={() => setShowPromotionFactEdit({promotion_id: promotion.id, fact: ''})}
                            />
                          </div>
                        ]}
                      >
                        {
                          Object.values(promotion.fact).map((fact, index) => {
                            return (
                              <div key={index}>
                                <span>{fact.fact}</span>
                                <IconButton
                                  icon={<Edit/>}
                                  color="secondary"
                                  title="Bearbeiten"
                                  onClick={() => setShowPromotionFactEdit(fact)}
                                />
                              </div>
                            );
                          })
                        }
                      </Accordion>
                    </td>
                  </tr>
                </Fragment>
              )}
            </>
        }
      </Table>
      {
        !loading && totalPromotions > promotions.length &&
        <Pagination className="mt-2" total={totalPromotions} page={page} pageSize={pageSize} setPage={setPage}/>
      }

      {
        showPromotionEdit &&
        <Modal title="Aktionen" className="promotion-modal" onDismiss={() => {
          if (!submitting && !deleting) {
            setShowPromotionEdit(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }
        }}>
          <Formik initialValues={{
            image: showPromotionEdit.image_id
              ? {
                ...showPromotionEdit.image,
                name: showPromotionEdit.image.filename,
                type: showPromotionEdit.image.media_type
              }
              : null,
            headline: showPromotionEdit.headline,
            price: displayPrice(showPromotionEdit.price),
            credits: showPromotionEdit.credits ?? "",
            is_starred: parseInt(showPromotionEdit.is_starred),
            one_time_order: parseInt(showPromotionEdit.one_time_order),
            href: showPromotionEdit.href ?? '',
            promotion_id: showPromotionEdit.id ?? null,
            image_id: showPromotionEdit.image_id
          }} validationSchema={modalValidationSchema} validateOnChange onSubmit={submit}>
            {({values, setFieldValue}) => (
              <Form>
                <Row columns={{default: 2}}>
                  <div className="image modal-box">
                    {
                      values.image && values.image.url && !values.image.path &&
                      <img src={values.image.url} alt={values.image.filename}/>
                    }
                    <DropzoneInput name="image" label=""/>
                  </div>
                  <div className="modal-box">
                    <TextInput name="headline" label="Überschrift" required/>
                    <Row>
                      <TextInput name="price" label="Preis / €"/>
                      <TextInput
                        name="credits" label="Individuelle Punkte" icon={<X/>}
                        onIconClick={() => setFieldValue("credits", "")}
                      />
                    </Row>
                    <TextInput name="href" label="Link"/>
                    <Checkbox name="is_starred">Auf Startseite</Checkbox>
                    <Checkbox name="one_time_order">Aktion gibt nur 1x Punkte</Checkbox>
                    <div className="linked-products">
                      <label>Verknüpfte Produkte:</label>
                      {
                        !loadingProducts && products?.length > 0 &&
                        <ul>
                          {
                            products.map((product, index) =>
                              <li key={index}>{`${product.amount}x ${product.name} (${product.brand?.name})`}</li>)
                          }
                        </ul>
                      }
                      {
                        !loading && !products?.length &&
                        <div className="no-entries">
                          <EmptyPackage/>
                          Keine Einträge vorhanden.
                        </div>
                      }
                      {loading && <Loader/>}
                      <Button
                        text="Produkte auswählen" onClick={() => setShowSelectProduct(true)} disabled={submitting}
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <Button inverted onClick={() => {
                    if (!showSelectProduct) {
                      setShowPromotionEdit(null);
                      setReloadKey(reloadKey => reloadKey + 1);
                    }
                  }} text="Abbrechen" disabled={submitting}/>
                  {
                    values.promotion_id &&
                    <Button
                      color="danger" onClick={() => setShowDeleteConfirm(values.promotion_id)}
                      text="Löschen" className="Danger" disabled={submitting}
                    />
                  }
                  <Button
                    type="submit" text="Speichern" disabled={submitting} icon={submitting ? <Loader/> : null}
                    iconPosition="left"
                  />
                </Row>
              </Form>
            )}
          </Formik>
        </Modal>
      }
      {
        showSelectProduct &&
        <PromotionHasProductEdit
          promotion={showPromotionEdit} setProductIds={setPromotionProducts}
          productIds={products.map(product => ({id: product.id, amount: product.amount}))}
          onDismiss={() => setShowSelectProduct(false)}
        />
      }
      {
        showPromotionFactEdit &&
        <PromotionFactEdit title="Fakt bearbeiten"
          onDismiss={() => {
            setShowPromotionFactEdit(null);
            setReloadKey(reloadKey + 1);
          }}
          fact={showPromotionFactEdit}
        />
      }
      {
        showDeleteConfirm &&
        <Modal className={"DeleteConfirm"} title="Aktionen löschen"
          onDismiss={() => {
            if (!deleting) {
              setShowDeleteConfirm(false);
            }
          }}
        >
          <h2>Bitte bestätigen Sie das Löschen</h2>
          <Row>
            <Button inverted onClick={() => setShowDeleteConfirm(false)} text="Abbrechen" disabled={deleting}/>
            <Button
              type="submit" text="Löschen" onClick={() => doDelete(showDeleteConfirm)} disabled={deleting}
              icon={deleting ? <Loader/> : null} iconPosition="left"
            />
          </Row>
        </Modal>
      }
      {
        successMessage.length > 0 &&
        <Modal className={"SuccessMessage"} title={"Aktionen"}
          onDismiss={() => {
            setSubmitting(false);
            setShowPromotionEdit(null);
            setSuccessMessage('');
            setReloadKey(reloadKey + 1);
          }}>
          <h3>{successMessage}</h3>
          <p className="text-right"><Button text="OK" onClick={() => {
            setSubmitting(false);
            setShowPromotionEdit(null);
            setSuccessMessage('');
            setReloadKey(reloadKey + 1);
          }} inline/></p>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
