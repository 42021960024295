import "./promotion-has-product-edit.scss";
import Modal from "./modal";
import {TableHead} from "./table-head";
import React, {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import Table from "./table";
import IconAndText from "./icon-and-text";
import {Loader} from "./icons";
import AutoTitle from "./auto-title";
import ErrorModal from "./error-modal";
import Button from "./button";
import AmountSelection from "./amount-selection";

export default function PromotionHasProductEdit({promotion, productIds, setProductIds = productIds => {}, onDismiss}) {

  const crud = useCRUD();

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [totalProducts, setTotalProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hasProducts, setHasProducts] = useState(productIds);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    Promise.all([
      crud.data.bulk.read({
        entity: 'product', page: 0, page_size: 9999, order: ['asc', 'asc'], order_by: ['brand_id', 'name'],
        filter: crud.filter.and(
          crud.filter.notEquals('free_good', 1),
          crud.filter.notEquals('is_sales', 1),
          crud.filter.notEquals('is_field_sales', 1),
        )
      }),
      crud.data.bulk.read({entity: 'product_type'}),
    ])
      .then(async ([productResult, productTypeResult]) => {
        setProducts(await crud.expand(productResult.items,
          ['product_type' ,'brand', 'image:file']));
        setTotalProducts(productResult.total);
        setProductTypes(await crud.expand(productTypeResult.items, ['placeholder:file']));
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
    return () => clearTimeout(timeout);
  }, [crud, promotion.id]);

  const thead = [
    <TableHead property={{title: 'Auswählen'}} titleCenter/>,
    <TableHead property={{title: 'Bild'}} titleCenter/>,
    <TableHead property={{name: 'article_no', title: 'Art.-Nr.'}}/>,
    <TableHead property={{name: 'name', title: 'Name'}}/>,
    <TableHead property={{name: 'brand_id', title: 'Marke'}}/>,
    <TableHead property={{name: 'product_type_id', title: 'Produkttyp'}}/>
  ];

  return (
    <div className="PromotionsHasProductEdit">
      <Modal onDismiss={onDismiss} title="Verknüpfte Produkte auswählen">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalProducts === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && products.length > 0 &&
          <>
            <Button text="Übernehmen" onClick={() => {
              setProductIds(hasProducts.filter(product => product.amount > 0));
              onDismiss();
            }}/>
            <Table
              headEntries={thead} order={{orderBy: '', order: ''}}
              setOrder={() => {}} filter={[]} setFilter={() => {}} className="promotions-has-product-table"
            >
              {products.map((product, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td className='text-center'>
                    <AmountSelection
                      min="0"
                      onChange={state => {
                        if (hasProducts.filter(hasProduct => hasProduct.id === product.id).length > 0) {
                          setHasProducts([
                            ...hasProducts.filter(hasProduct => hasProduct.id !== product.id),
                            {id: product.id, amount: state}
                          ]);
                        } else {
                          setHasProducts([...hasProducts, {id: product.id, amount: state}]);
                        }
                      }}
                      amount={parseInt(hasProducts?.find(item => item.id === product.id)?.amount ?? 0)}
                    />
                  </td>
                  <td className="text-center">
                    <div className="TeaserImage">
                      {<img
                        src={
                          (product?.image && product?.image?.url) ||
                          (
                            productTypes.length > 0 &&
                            productTypes.find(item => item.id === product.product_type_id)?.placeholder?.url
                          ) || ''
                        }
                        alt={
                          (product.image && product.image.filename) ||
                          (
                            productTypes.length > 0 &&
                            productTypes.find(item => item.id === product.product_type_id)?.placeholder?.filename
                          ) || ''
                        }
                      />}
                    </div>
                  </td>
                  <td>
                    <AutoTitle>{product.article_no && product.article_no}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.brand && product.brand.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.product_type && product.product_type.name}</AutoTitle>
                  </td>
                </tr>)}
            </Table>
            <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
          </>
        }
      </Modal>
    </div>
  );
}
