import './icon-button.scss';

export default function IconButton({icon, color, onClick, className, title}) {
  return (
    <span
      className={'IconButton' + (color ? ' ' + color : '') + (className ? ' ' + className : '')}
      onClick={onClick} title={title}
    >
      {icon}
    </span>
  );
}
