import {Route, Routes} from "react-router-dom";
import Anmelden from "./routes/anmelden";
import {useEffect, useState} from "react";
import {AuthContext} from "./context/auth-context";
import useStorage from "./hook/useStorage";
import RequireAuth from "./components/require-auth";
import Menu from "./screen-element/menu";
import Kunden from "./routes/kunden";
import Admin from "./routes/admin";
import Produkte from "./routes/produkte";
import Aktionen from "./routes/aktionen";
import Gutscheine from "./routes/gutscheine";
import Marken from "./routes/marken";
import Grosshaendler from "./routes/grosshaendler";
import Downloads from "./routes/downloads";
import Profil from "./routes/profil";
import ResetPassword from "./routes/reset-password";
import useClaims from "./hook/useClaims";
import useBL from "./hook/useBL";
import ErrorModal from "./components/error-modal";
import Bestellungen from "./routes/bestellungen";
import Geschaefte from "./routes/geschaefte";
import Produkttypen from "./routes/produkttypen";
import Aussendienst from "./routes/aussendienst";
import Handel from "./routes/handel";
import NRProdukte from "./routes/nr-produkte";
import Willkommensbild from "./routes/willkommensbild";
import Onlinehandel from "./routes/onlinehandel";
import Dashboard from "./routes/dashboard";

export default function App() {

  const storage = useStorage();
  const [authToken, setAuthToken] =
    useState(storage.local.get('authToken') ?? storage.session.get('authToken') ?? '');
  const claims = useClaims(authToken);
  const bl = useBL(authToken);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (authToken) {
      const deleteAuthToken = () => {
        setAuthToken(null);
        storage.local.remove('authToken');
        storage.session.remove('authToken');
      };
      let tokenDate = new Date(claims.exp).getTime();
      let now = new Date().getTime();
      if (tokenDate < now) {
        deleteAuthToken();
      } else {
        bl.renew_auth_token()
          .then((token) => {
            setAuthToken(token);
            if (storage.local.get('authToken')) {
              storage.local.set('authToken', token);
            } else {
              storage.session.set('authToken', token);
            }
          })
          .catch(errors => deleteAuthToken() || setErrors(errors));
      }
    }
  }, [authToken, bl, claims.exp, storage.local, storage.session]);

  return (
    <div className="App">
      <AuthContext.Provider value={{authToken, setAuthToken}}>
        {
          authToken &&
          <Menu/>
        }
        <Routes>
          <Route path="anmelden" element={<Anmelden/>}/>
          <Route path="dahboard" element={<RequireAuth><Dashboard/></RequireAuth>}/>
          <Route path="kunden" element={<RequireAuth><Kunden/></RequireAuth>}/>
          <Route path="kunden/:id/geschaefte" element={<RequireAuth><Geschaefte/></RequireAuth>}  index={false}/>
          <Route path="kunden/:id/bestellungen" element={<RequireAuth><Bestellungen/></RequireAuth>}  index={false}/>
          <Route path="bestellungen" element={<RequireAuth><Bestellungen/></RequireAuth>} index={true}/>
          <Route path="admin" element={<RequireAuth><Admin/></RequireAuth>}/>
          <Route path="aussendienst" element={<RequireAuth><Aussendienst/></RequireAuth>}/>
          <Route path="handel" element={<RequireAuth><Handel/></RequireAuth>}/>
          <Route path="onlinehandel" element={<RequireAuth><Onlinehandel/></RequireAuth>}/>
          <Route path="geschaefte" element={<RequireAuth><Geschaefte/></RequireAuth>}/>
          <Route path="grosshaendler" element={<RequireAuth><Grosshaendler/></RequireAuth>}/>
          <Route path="produkte" element={<RequireAuth><Produkte/></RequireAuth>}/>
          <Route path="nr-produkte" element={<RequireAuth><NRProdukte/></RequireAuth>}/>
          <Route path="aktionen" element={<RequireAuth><Aktionen/></RequireAuth>}/>
          <Route path="willkommensbild" element={<RequireAuth><Willkommensbild/></RequireAuth>}/>
          <Route path="gutscheine" element={<RequireAuth><Gutscheine/></RequireAuth>}/>
          <Route path="marken" element={<RequireAuth><Marken/></RequireAuth>}/>
          <Route path="produkttypen" element={<RequireAuth><Produkttypen/></RequireAuth>}/>
          <Route path="downloads" element={<RequireAuth><Downloads/></RequireAuth>}/>
          <Route path="profil" element={<RequireAuth><Profil/></RequireAuth>}/>
          <Route path="/passwort-zuruecksetzen/:token" element={<ResetPassword/>}/>
          <Route path="*" element={<RequireAuth><Dashboard/></RequireAuth>}/>
        </Routes>
      </AuthContext.Provider>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
