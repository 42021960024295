import Screen from "../screen-element/screen";
import StatisticDiagram from "../components/statistic-diagram";

export default function Dashboard() {
  return (
    <Screen>
      <h1>Dashboard</h1>
      <StatisticDiagram/>
    </Screen>
  );
}

