import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Edit, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import {displayPrice} from "../functions";
import Row from "../components/row";
import EditModal from "../components/edit-modal";
import TextInput from "../components/text-input";
import DropzoneInput from "../components/DropzoneInput";
import Dropdown from "../components/dropdown";
import DropdownItem from "../components/dropdown-item";
import Checkbox from "../components/checkbox";
import * as Yup from "yup";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";

export default function Produkte() {

  const pageSize = 10;

  const crud = useCRUD();

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [brands, setBrands] = useState([]);
  const [totalProducts, setTotalProducts] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Artikel-Nummer', orderBy: 'article_no', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);
  const [isSales, setIsSales] = useState(false);
  const [isFieldSales, setIsFieldSales] = useState(false);
  const [noPrice, setNoPrice] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const modalValidationSchema = Yup.object({
    article_no: Yup.string()
      .required('Bitte eine Artikelnummer eingeben'),
    name: Yup.string()
      .required('Bitte eine Bezeichnung angeben'),
    price: Yup.string()
      .matches(/^[0-9]\d*(,\d{1,2})?$/, "Bitte den Preis im Format '1,00' angeben"),
    image: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      )
  });

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    Promise.all([
      crud.data.bulk.read({
        entity: 'product', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order,
        filter: filter.length ?
          crud.filter.and(...filter, crud.filter.notEquals('free_good', 1)) :
          crud.filter.notEquals('free_good', 1)
      }),
      crud.data.bulk.read({entity: 'product_type', page_size: 1000}),
      crud.data.bulk.read({entity: 'brand', page_size: 1000}),
    ])
      .then(async ([productResult, productTypeResult, brandResult]) => {
        setProducts(await crud.expand(productResult.items, ['product_type' ,'brand', 'image:file']));
        setTotalProducts(productResult.total);
        setProductTypes(await crud.expand(productTypeResult.items, ['placeholder:file']));
        setBrands(brandResult.items);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
    return () => clearTimeout(timeout);
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{title: 'Bild'}} titleCenter/>,
    <TableHead property={{name: 'article_no', title: 'Art.-Nr.'}} sortable filterable/>,
    <TableHead property={{name: 'product_type_id', title: 'Produkttyp'}} filterable type="dropdown" 
      dropdownList={productTypes} dropdownColumn="name" dropdownDatabaseEntry="id"/>,
    <TableHead property={{name: 'brand_id', title: 'Marke'}} filterable type="dropdown" dropdownList={brands}
      dropdownColumn="name" dropdownDatabaseEntry="id"/>,
    <TableHead property={{name: 'name', title: 'Name'}} sortable filterable/>,
    <TableHead property={{name: 'description', title: 'Beschreibung'}} sortable filterable/>,
    <TableHead property={{name: 'price', title: 'Preis'}} sortable filterable type="price" titleRight/>,
    <TableHead property={[
      {name: 'is_new', title: 'Neu'}, {name: 'is_top_seller', title: 'Topseller'},
      {name: 'is_out_of_stock', title: 'z.Zt. nicht verfügbar'}, {name: 'is_sales', title: 'Handel'},
      {name: 'is_field_sales', title: 'Außendienst'}
    ]}
    filterable type="checkbox" titleCenter/>,
    <TableHead titleRight resetFilter/>
  ];

  return (
    <Screen className="ProductPage">
      <h1 className="text-center">Produkte</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neues Produkt anlegen" onClick={() => {
            setSelectedProductType(null);
            setSelectedProduct(
              {
                image: null,
                article_no: '',
                name: '',
                description: '',
                price: '',
                brand_id: null,
                product_type_id: null,
                is_new: '0',
                is_top_seller: '0',
                is_out_of_stock: '0',
                is_sales: '0',
                is_field_sales: '0',
                free_good: '0'
              }
            );
          }} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="product-table">
        {
          loading && showLoading &&
            <tr>
              <td className="col-span">
                <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
              </td>
            </tr>
        }
        {
          !showLoading && totalProducts === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && products.length > 0 &&
            <>
              {products.map((product, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td className="text-center">
                    <div className="TeaserImage">
                      {<img
                        src={
                          (product?.image && product?.image?.url) ||
                          (
                            productTypes.length > 0 &&
                            productTypes.find(item => item.id === product.product_type_id)?.placeholder?.url
                          ) || ''
                        }
                        alt={
                          (product.image && product.image.filename) ||
                          (
                            productTypes.length > 0 &&
                            productTypes.find(item => item.id === product.product_type_id)?.placeholder?.filename
                          ) || ''
                        }
                      />}
                    </div>
                  </td>
                  <td>
                    <AutoTitle>{product.article_no && product.article_no}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.product_type && product.product_type.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.brand && product.brand.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{product.description}</AutoTitle>
                  </td>
                  <td className='text-right padding-right-05'>
                    <AutoTitle>{product.price && displayPrice(product.price)}</AutoTitle>
                  </td>
                  <td className="text-center">
                    {product.is_new === '1' && <span className="tags">NEU</span>}
                    {product.is_top_seller === '1' && <span className="tags">TOPSELLER</span>}
                    {product.is_out_of_stock === '1' && <span className="tags danger">z.Zt. nicht verfügbar</span>}
                    {product.is_sales === '1' && <span className="tags">Handel</span>}
                    {product.is_field_sales === '1' && <span className="tags">Außendienst</span>}

                  </td>
                  <td className='text-right icon-row'>
                    <div onClick={() => {
                      setSelectedProductType(productTypes.find(type => (type.id === product.product_type_id)));
                      setSelectedProduct(product);
                      setIsSales(product.is_sales === '1');
                      setIsFieldSales(product.is_field_sales === '1');
                      setNoPrice(product.is_sales === '1' || product.is_field_sales === '1');
                    }} title="Produkt bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>)}
            </>
        }
      </Table>
      {
        !loading && totalProducts > products.length &&
        <Pagination className="mt-2" total={totalProducts} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {
        !loading && selectedProduct &&
        <EditModal title="Produkt" itemId={selectedProduct.id ?? null} validationSchema={modalValidationSchema}
          databaseName="product" additionalValues={{free_good : 0}}
          onDismiss={() => {
            setSelectedProduct(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}>
          <div>
            {
              <img
                src={
                  (selectedProduct.image && selectedProduct.image.url ) ||
                  (selectedProductType && selectedProductType.placeholder && selectedProductType.placeholder.url)
                }
                alt={
                  (selectedProduct.image && selectedProduct.image.filename) ||
                  (selectedProductType && selectedProductType.placeholder && selectedProductType.placeholder.filename)
                }
              />
            }
            <DropzoneInput name="image" label=""
              value={
                selectedProduct?.image ?
                  {
                    ...selectedProduct.image,
                    name: selectedProduct.image.filename,
                    type: selectedProduct.image.media_type
                  } :
                  null
              }/>
          </div>
          <div>
            <TextInput value={selectedProduct.article_no} name="article_no" label="Artikelnummer" required/>
            <TextInput value={selectedProduct.name} name="name" label="Produktbezeichnung" required/>
            <TextInput value={selectedProduct.description} name="description" label="Beschreibung"/>
            {!noPrice && <TextInput value={selectedProduct.price} name="price" label="Preis / €"/>}
          </div>
          <div>
            <Checkbox value={parseInt(selectedProduct.is_top_seller)} name="is_top_seller">TOPSELLER</Checkbox>
            <Checkbox value={parseInt(selectedProduct.is_new)} name="is_new">NEU</Checkbox>
            <Checkbox value={parseInt(selectedProduct.is_out_of_stock)} name="is_out_of_stock">
              z.Zt. nicht verfügbar
            </Checkbox>
            <Checkbox value={parseInt(selectedProduct.is_sales)} name="is_sales"
              onChange={(value) => {setIsSales(value);
                setNoPrice(value || isFieldSales);}}
            >Handel</Checkbox>
            <Checkbox value={parseInt(selectedProduct.is_field_sales)} name="is_field_sales"
              onChange={(value) => {setIsFieldSales(value);
                setNoPrice(value || isSales);}}
            >Außendienst</Checkbox>
          </div>
          <div>
            <Dropdown name="product_type_id" label="Produkttyp" selectOption
              value={selectedProduct?.product_type?.id}>
              {
                productTypes.map((productType, index) => (
                  <DropdownItem databaseEntry={productType.id} key={index}>
                    {productType.name}
                  </DropdownItem>
                ))
              }
            </Dropdown>
            <Dropdown name="brand_id" label="Marke" selectOption
              value={selectedProduct?.brand?.id}>
              {
                brands.map((brand, index) => (
                  <DropdownItem databaseEntry={brand.id} key={index}>
                    {brand.name}
                  </DropdownItem>
                ))
              }
            </Dropdown>
          </div>
        </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
