import Modal from "./modal";
import {useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import useBL from "../hook/useBL";
import PasswordInput from "./password-input";
import Button from "./button";
import {Loader} from "./icons";
import Row from "./row";
import ErrorModal from "./error-modal";

export default function ChangePassword({onDismiss}) {

  const bl = useBL();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    old_password: Yup.string().required('Bitte das alte Passwort eingeben'),
    new_password: Yup.string()
      .required('Bitte ein neues Passwort eingeben')
      .notOneOf([Yup.ref('old_password')], 'Das neue Passwort darf nicht identisch mit dem alten sein'),
    new_password_repetition: Yup.string()
      .required('Bitte das neue Passwort wiederholen')
      .oneOf([Yup.ref('new_password')], 'Passwörter müssen übereinstimmen')
  });

  const submit = (values, {setSubmitting}) => {
    bl.change_password({old_password: values.old_password, new_password: values.new_password})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal title="Passwort ändern" onDismiss={onDismiss}>
      {
        !showSuccess &&
        <>
          <Formik
            initialValues={{old_password: '', new_password: '', new_password_repetition: ''}}
            validationSchema={validationSchema} onSubmit={submit} validate={() => setErrors([])}
          >
            {({isSubmitting}) => (
              <Form>
                <PasswordInput name="old_password" placeholder="Altes Passwort"/>
                <PasswordInput className="mt-025" name="new_password" placeholder="Neues Passwort"/>
                <PasswordInput
                  className="mt-025" name="new_password_repetition" placeholder="Neues Passwort wiederholen"
                />
                <Row className="mt-1" gap="1" justify="end">
                  <Button text="Abbrechen" onClick={onDismiss} inverted inline/>
                  <Button
                    text="Speichern" type="submit" icon={isSubmitting ? <Loader/> : null} disabled={isSubmitting}
                    inline iconPosition="right"
                  />
                </Row>
              </Form>
            )}
          </Formik>
          <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        </>
      }
      {
        showSuccess &&
        <>
          <h3>Das neue Passwort kann nun für die Anmeldung verwendet werden.</h3>
          <p className="text-right"><Button text="OK" onClick={onDismiss} inline/></p>
        </>
      }
    </Modal>
  );
}
