import Table from "../components/table";
import useCRUD from "../hook/useCRUD";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/error-modal";
import Pagination from "../components/pagination";
import {Check, Edit, Loader} from "../components/icons";
import IconAndText from "../components/icon-and-text";
import Button from "../components/button";
import Screen from "../screen-element/screen";
import Row from "../components/row";
import * as Yup from "yup";
import DropzoneInput from "../components/DropzoneInput";
import TextInput from "../components/text-input";
import EditModal from "../components/edit-modal";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import DropdownItem from "../components/dropdown-item";
import Dropdown from "../components/dropdown";
import Checkbox from "../components/checkbox";

export default function Produkttypen() {

  const pageSize = 10;

  const crud = useCRUD();

  const [productTypes, setProductTypes] = useState([]);
  const [totalProductTypes, setTotalProductTypes] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Name', orderBy: 'name', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showProductTypeEdit, setShowProductTypeEdit] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read(
      {entity: 'product_type', page, page_size: pageSize,
        order_by: order.orderBy, order: order.order, filter: filter.length ? crud.filter.and(...filter) : {}})
      .then(async (productTypeBulkReadResult) => {
        setProductTypes(await crud.expand(productTypeBulkReadResult.items, ['placeholder:file', 'symbol:file']));
        setTotalProductTypes(productTypeBulkReadResult.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, reloadKey, filter]);

  const thead = [
    <TableHead property={{name: 'placeholder',title: 'Platzhalter'}}/>,
    <TableHead property={{name: 'symbol', title: 'Symbol'}}/>,
    <TableHead property={{name: 'name', title: 'Name'}} sortable filterable/>,
    <TableHead property={{name: 'price_info', title: 'Preis Info'}} sortable filterable/>,
    <TableHead property={{name: 'is_sales', title: 'Handel'}} sortable/>,
    <TableHead property={{name: 'is_field_sales', title: 'Außendienst'}} sortable/>,
    <TableHead titleRight resetFilter/>
  ];
  const modalValidationSchema = Yup.object({
    name: Yup.string()
      .required('Bitte eine Bezeichnung angeben'),
    placeholder: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'fileNameLength',
        'Der Dateiname ist zu lang! Maximale Zeichen: 120',
        (val) => (val?.name?.length ?? 0) <= 120
      )
      .test(
        'fileSize',
        'Die Datei ist zu groß! Maximale Größe: 100MB',
        (val) => (val?.size ?? 0) <= 100000000
      )
      .test('type', 'Erlaubte Bildformate: PNG, JPG, GIF, WEBP und SVG', val => [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/webp',
        ''
      ].includes(val ? val.type : '')
      )
  });
  return (
    <Screen className="ProductTypePage">
      <h1 className="text-center">Produkttypen</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Produkttyp anlegen" onClick={() => setShowProductTypeEdit(
            {
              placeholder: {
                url: '',
                filename: ''
              },
              name: '',
              price_info: null,
              symbol: {
                path: '',
                filename: ''
              },
              is_sales: '0',
              is_field_sales: '0',
            }
          )} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="producttypes-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalProductTypes === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && productTypes.length > 0 &&
            <>
              {productTypes.map((productType, index) =>
                <tr key={index} className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td>
                    <div className="TeaserImage">
                      {
                        productType.placeholder &&
                        <img src={productType.placeholder.url}
                          alt={productType.placeholder.filename}/>
                      }
                    </div>
                  </td>
                  <td>
                    <div className="TeaserImage">
                      {
                        productType?.symbol?.name &&
                        <img src={productType.symbol.url}
                          alt={productType.symbol.filename}/>
                      }
                    </div>
                  </td>
                  <td>
                    <AutoTitle>{productType.name && productType.name}</AutoTitle>
                  </td>
                  <td>
                    <AutoTitle>{productType.price_info && productType.price_info}</AutoTitle>
                  </td>
                  <td>{productType.is_sales === '1' && <Check/>}</td>
                  <td>{productType.is_field_sales === '1' && <Check/>}</td>
                  <td className="text-right icon-row">
                    <div onClick={() => setShowProductTypeEdit(productType)} title="Produkttyp bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>)}
            </>
        }
      </Table>
      {
        !loading && totalProductTypes > productTypes.length &&
        <Pagination className="mt-2" total={totalProductTypes} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showProductTypeEdit &&
      <EditModal title="Produkttyp" itemId={showProductTypeEdit.id ?? null} validationSchema={modalValidationSchema}
        databaseName="product_type"
        onDismiss={() => {
          setShowProductTypeEdit(null);
          setReloadKey(reloadKey => reloadKey + 1);
        }}
      >
        <div className="image">
          {
            showProductTypeEdit.placeholder &&
            <img src={showProductTypeEdit.placeholder && showProductTypeEdit.placeholder.url}
              alt={showProductTypeEdit.placeholder.filename}/>
          }
          <DropzoneInput name="placeholder" label="Platzhalter"
            value={
              showProductTypeEdit?.placeholder?.filename ?
                {
                  ...showProductTypeEdit.placeholder,
                  name: showProductTypeEdit.placeholder.filename,
                  type: showProductTypeEdit.placeholder.media_type
                } :
                null
            }/>
        </div>
        <div>
          <TextInput name="name" label="Name" value={showProductTypeEdit?.name} required/>
          <Dropdown name="price_info" label="Preis Info" selectOption value={showProductTypeEdit?.price_info}>
            <DropdownItem databaseEntry={'UVP'} value={'UVP'}>UVP</DropdownItem>
            <DropdownItem databaseEntry={'KVP'} value={'KVP'}>KVP</DropdownItem>
          </Dropdown>
          {
            showProductTypeEdit.symbol &&
            <div className="SymbolImage">
              <img src={showProductTypeEdit.symbol && showProductTypeEdit.symbol.url}
                alt={showProductTypeEdit.symbol.filename}/>
            </div>
          }
          <DropzoneInput name="symbol" label="Symbol"
            value={
              showProductTypeEdit?.symbol?.filename ?
                {
                  ...showProductTypeEdit.symbol,
                  name: showProductTypeEdit.symbol.filename,
                  type: showProductTypeEdit.symbol.media_type
                } :
                null
            }/>
          <Checkbox value={parseInt(showProductTypeEdit.is_sales)} name="is_sales">Handel</Checkbox>
          <Checkbox value={parseInt(showProductTypeEdit.is_field_sales)} name="is_field_sales">Außendienst</Checkbox>
        </div>
      </EditModal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}

