import {FieldArray, Form, Formik} from "formik";
import Dropdown from "./dropdown";
import DropdownItem from "./dropdown-item";
import DateTimeInput from "./date-time-input";
import LineChart from "./line-chart";
import useBL from "../hook/useBL";
import {useEffect, useState} from "react";
import {EmptyPackage, Loader} from "./icons";
import ErrorModal from "./error-modal";
import './statistics-diagram.scss';
import Checkbox from "./checkbox";
import Row from "./row";
import Button from "./button";

export default function StatisticDiagram() {
  const bl = useBL();
  const [datasets, setDatasets] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeUnit, setTimeUnit] = useState("day");
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString());
  const [endDate, setEndDate] = useState(new Date(new Date().toISOString()));
  const [lineDatasets, setLineDatasets] = useState([
    {dataTypeEN: "sales_volume", dataTypeDE: "Umsatz",       used: true,  color: "green"},
    {dataTypeEN: "product",      dataTypeDE: "Produkte",     used: false, color: "blue"},
    {dataTypeEN: "order",        dataTypeDE: "Bestellungen", used: false, color: "red"}
  ]);

  useEffect(() => {
    setLoading(true);
    let getStatistics = [];
    lineDatasets.forEach(lineDataset =>
      getStatistics.push(bl.get_statistics({
        time_unit: timeUnit,
        data_type: lineDataset.dataTypeEN,
        start_date: startDate ?? endDate,
        end_date: endDate ?? startDate
      }))
    );

    Promise.all(getStatistics)
      .then(result => {
        let newDatasets = [];
        result.forEach((dataset, index) => {
          if (lineDatasets[index].used) {
            newDatasets.push({
              pointBackgroundColor: lineDatasets[index].color,
              pointBorderWidth: 2,
              pointRadius: 5,
              pointBorderColor: "white",
              borderColor: lineDatasets[index].color,
              borderWidth: 1,
              label: lineDatasets[index].dataTypeDE,
              data: dataset.data.map(item => {
                return ({x: item[0], y: item[1]});
              }),
              yAxisID: dataset.type === "sales_volume"  ? "ySalesVolume" : "yProductAndOrder",
              spanGaps: true,
              tension: 0.3
            });
          }
        });
        setDatasets(newDatasets);
      })
      .catch(error => {
        setDatasets([]);
        setErrors(error);
      })
      .finally(() => setLoading(false));
  }, [bl, endDate, lineDatasets, startDate, timeUnit]);

  return (
    <div className="StatisticDiagram">
      <Formik
        initialValues={{
          timeUnitDropdown: timeUnit,
          startDate: startDate,
          endDate: endDate,
          lineDatasets: lineDatasets
        }}
        validate={values => setLineDatasets(values.lineDatasets)}
        onSubmit={(values, {setSubmitting}) => {
          console.log((new Date(values.endDate).getTime() - new Date(values.startDate).getTime()));
          if (new Date(values.startDate).getTime() > new Date(values.endDate).getTime()) {
            setErrors([{message: "Das Enddatum darf nicht vor dem Startdatum liegen"}]);
          } else if (
            (new Date(values.startDate).getTime() < 0)
            || (new Date(values.endDate).getTime() < 0)
          ) {
            setErrors([{message: "Bitte ein Datum nach 1970 wählen"}]);
          } else if (
            (new Date(values.endDate).getTime() - new Date(values.startDate).getTime())
            > (50 * 365 * 24 * 60 * 60 * 1000)
          ) {
            setErrors([{message: "Die Zeit zwischen Start und Enddatum darf nicht mehr als 50 Jahre betragen"}]);
          } else {
            setTimeUnit(values.timeUnitDropdown);
            setStartDate(values.startDate);
            setEndDate(values.endDate);
          }
          setSubmitting(false);
        }}>
        {({values}) => (
          <Form>
            <div className="upper-elements">
              <div className="inputs">
                <div>
                  <Dropdown name="timeUnitDropdown" label="Zeitspanne">
                    <DropdownItem databaseEntry="day">Tag</DropdownItem>
                    <DropdownItem databaseEntry="month">Monat</DropdownItem>
                    <DropdownItem databaseEntry="quarter">Quartal</DropdownItem>
                    <DropdownItem databaseEntry="year">Jahr</DropdownItem>
                  </Dropdown>
                </div>
                <DateTimeInput name="startDate" label="Von"/>
                <DateTimeInput name="endDate" label="Bis"/>
                <Button text="Anwenden" inline type="submit"/>
              </div>
              <FieldArray name="lineDatasets" render={() => (
                <Row className="graph-selection-section">
                  {
                    values.lineDatasets.map((lineDataset, index) => (
                      <div key={index}>
                        <div key={index} className="graph-selection" style={{borderColor: lineDataset.color}}>
                          <Checkbox name={`lineDatasets[${index}].used`}>
                            {lineDatasets[index].dataTypeDE}
                          </Checkbox>
                        </div>
                      </div>
                    ))
                  }
                </Row>
              )}/>
            </div>
            {loading && <Loader/>}
            {
              !loading && datasets.length === 0 && lineDatasets.length > 0
              && <div className="no-dataset"><EmptyPackage/> Keine Einträge</div>
            }
            {
              !loading && datasets.length === 0 && lineDatasets.length === 0
              && <div className="no-dataset"><EmptyPackage/> Kein Datensatz hinzugefügt</div>
            }
            {
              !loading && datasets.length > 0 &&
              <LineChart datasets={datasets} timeUnit={timeUnit} className="line-chart"/>
            }
          </Form>
        )}
      </Formik>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
